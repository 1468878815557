import React, { useEffect, useState } from 'react'

interface Props {
    size: number;
    maxTime: number;
    onCounterEnd: () => void;
}
export const CountTimer = (props: Props) => {
    // let timeInterval: NodeJS.Timer;
    const [currentTime, setCurrentTime] = useState(props.maxTime);
    const [timerInterval, setTimerInterval] = useState<NodeJS.Timer>();

    useEffect(() => {

        if (currentTime < 1) {
            clearInterval(timerInterval);
            if(props.onCounterEnd) {
                props.onCounterEnd();
            }
        }
    }, [currentTime]);

    useEffect(() => {
        const timeInterval = setInterval(() => {
            setCurrentTime(previousTime => previousTime - 1);
        }, 1000);

        setTimerInterval(timeInterval);

        return () => {
            clearInterval(timeInterval);
        }
    }, []);

    return (
        <div className='counter-timer' style={{
            width: props.size,
            height: props.size
        }}>
            <h2>{currentTime}</h2>
        </div>
    )
}
