import { PlayerMove, PlayerMoveReq } from "../model/dto/req/player-move";
import { ScoreSubmitReq } from "../model/score-submit.req";
import API from "../utility/axios";

export class ScoreService {
    constructor(){}

    static submitScore(data: ScoreSubmitReq): Promise<boolean>{
        return API.post(`/scores`,data);
    }

    static getMyScore(game: string, user: string): Promise<any> {
        return API.get(`/scores/${user}/${game}`);
    }

    static submitPlayerMoves(data: PlayerMoveReq) {
        return API.post(`/player-move`,data);
    }
}