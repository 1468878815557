
import { MediaUploadResponse } from "../model/media-upload.res";
import API from "../utility/axios";

export const uploadMediaToUrl = (url: string, data: any, type: string): Promise<void> => {
    return API.put(url, data, {
        headers: {
            'Content-Type': type
        }
    });
}

export const getMediaUploadUrl = (data: any): Promise<MediaUploadResponse> => {
    return API.post(`/media/upload-url`, data);
}