import API from "../utility/axios";

export class SocialService {

    static GetPeople(userID:any): Promise<void> {
        return API.get(`/social/find/${userID}`);
    }

    static GetFollowStatus(data:any): Promise<void> {
        return API.post(`/social/follow`,data);
    }
    static GetFriends(userID:any): Promise<void> {
        return API.get(`/social/friends/${userID}`);
    }

    static UnFollowFreind(userID:any): Promise<void> {
        return API.delete(`/social/unfriend/${userID}`);
    }
}