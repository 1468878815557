export const BASE_URL = "https://api.moplay.in";
// export const BASE_URL = "http://localhost:4211";
export const GAME_URL = "https://moplay-website.s3.ap-south-1.amazonaws.com/";
export const ASSETS_URL = "https://moplay-website.s3.ap-south-1.amazonaws.com/";
export const GOOGLE_CLIENT_ID = "346745340517-7tfbbv8cmn958pgl3lt776pjhvgar7b7.apps.googleusercontent.com"

const appConfig = {
  BASE_URL: BASE_URL,
  GAME_URL:GAME_URL,
  GOOGLE_CLIENT_ID : GOOGLE_CLIENT_ID,
  // SOCKET_URL: 'http://localhost:4211',
  SOCKET_URL: 'https://api.games.mrdev.co.in',
  JWT_SIGNITURE: 'moplay' 
};
export default appConfig;
