import React, { useEffect } from 'react';
import logo from './logo.svg';
import './App.css';
import './assets/styles/css/styles.css';
import { BrowserRouter, Route, RouterProvider, Routes, } from 'react-router-dom';
import RootNavigation from './routes'
import { Provider, connect } from 'react-redux';
import { ReduxStore } from './app/redux/store';
import { GoogleOAuthProvider } from '@react-oauth/google';
import Header from './components/header';
import { SideMenu } from './components/side-menu';
import Popups from './components/popups';
import { SocketSvc } from './app/service/socket.service';
import { MobileViewMenu } from './components/mobile-view-menu';
import Footer from './app/components/footer';
// import { AppRouter } from './components/form-builder';

function App() {
  let isSocketInitialized = false;
  useEffect(() => {
    let vh = window.innerHeight;
    // Then we set the value in the --vh custom property to the root of the document
    document.documentElement.style.setProperty('--vh', `${vh}px`);
    if (!isSocketInitialized) {
      isSocketInitialized = true;
      SocketSvc.initialize();
    }
  }, [])



  return (
    <GoogleOAuthProvider clientId='297176270720-k41ffmg79ab0sr6hbd9kf4o3e4lmqlnf.apps.googleusercontent.com'>
      <Provider store={ReduxStore}>
        <div className="App moplay game-theme wix-text position-relative">
          <BrowserRouter>
            <div className="row px-0 mx-0">
              <div className='d-block d-md-none'>
                <MobileViewMenu />
              </div>
              <div className='col-12 px-0 mx-0'>
                <RootNavigation />
              </div>
            </div>
            <Popups></Popups>
            
          </BrowserRouter>
        </div>
      </Provider>
    </GoogleOAuthProvider>
  );
}

export default App;
