import React, { useEffect, useRef, useState } from 'react'
import { GAME_URL } from '../config/constant';
import STATIC_GAME_ICON from "./../assets/images/static-game.jpg";
import { Link } from 'react-router-dom';
import { GamesList } from './games-list';
import { GamePublisherDetails, PublisherDetailsById } from '../app/service/game.service';

interface Props {
    publisherData?: any;
    hideSearchBar?: any;
    publisherModalStatus?: any;
}

export const PublisherPage = (props: Props) => {
    const [publishersData, setPublishersData] = useState<any>([]);
    const [gamePublisherData, setGamePublisherData] = useState<any>([]);
    const aboutTabRef: any = useRef<HTMLDivElement>(null);

    useEffect(() => {
        if (props.publisherData?._id) {
            GamePublisherDetails(props?.publisherData?.publisher || '').then((res: any) => {
                setPublishersData(res)
            })
            PublisherDetailsById(props?.publisherData?.publisher || '').then((res: any) => {
                setGamePublisherData(res)
            })
        }
    }, [props?.publisherData?.publisher])

    const closePublisherModal = () => {
        props?.publisherModalStatus(true);
    }

    const handleAboutClick = () => {
        if (aboutTabRef.current) {
            aboutTabRef.current.click();
        }
    };

    return (
        <>
            <div className="shadow-bottom bg-white" >
                <div className="px-3 pb-1">
                    <div className='d-flex py-2 '>
                        <div className='d-flex align-items-center'>
                            <span className="position-relative me-2" style={{ bottom: "3px" }}><Link to="/" className='text-decoration-none my-2 me-2'> <i className="bi bi-arrow-left mb-2 text-dark" onClick={closePublisherModal}></i></Link></span>
                            <img src={GAME_URL + gamePublisherData?.icon} className="mp-publisher-profile-icon" />
                        </div>
                        <div className='mx-3 d-flex align-items-center'>
                            <div className='text-dark'>{gamePublisherData?.name}</div>
                        </div>
                    </div>
                    <div className='text-primary mb-2 mp-cursor-pointer' onClick={handleAboutClick}>{gamePublisherData?.about && (gamePublisherData.about.length > 40 ? gamePublisherData.about.slice(0, 40) + ' ...' : gamePublisherData.about)}</div>
                </div>
            </div>
            <div className='container-fluid bg-white text-dark position-relative px-2 mp-scroll-bar-width' style={{
                overflowY: 'auto',
                paddingBottom: 250,
                maxHeight: '100vh'
            }}>
                <div className="text-dark pt-0 text-dark">
                    {/* <div style={{ height: '100%', overflowY: "auto", scrollbarWidth: "none" }}> */}
                    <div style={{ overflowY: "auto", scrollbarWidth: "none" }}>
                        <div className='my-2 my-md-0'>
                            <img src={GAME_URL + gamePublisherData?.banner} className="mp-publisher-banner-image" />
                        </div>
                        <div className='my-4' >
                            <ul className="nav nav-tabs" id="myTab" role="tablist">
                                <li className="nav-item" role="presentation">
                                    <button className="nav-link active title-reg" id="games-tab" data-bs-toggle="tab" data-bs-target="#games" type="button" role="tab" aria-controls="games" aria-selected="false">Games</button>
                                </li>
                                <li className="nav-item" role="presentation">
                                    <button className="nav-link title-reg" id="home-tab" data-bs-toggle="tab" data-bs-target="#home" type="button" role="tab" aria-controls="home" aria-selected="true" ref={aboutTabRef}>About</button>
                                </li>
                            </ul>
                            <div className="tab-content" id="myTabContent">
                                <div className="tab-pane fade" id="home" role="tabpanel" aria-labelledby="home-tab">
                                    <div className="my-2" style={{ height: "300px", overflowY: "auto" }} >
                                        <div className='fs-4 fw-bold title-reg'>{props?.publisherData?.name}</div>
                                        <div className='fs-5'>{gamePublisherData?.name}</div>
                                        <div className='f-14' >{gamePublisherData?.about}</div>
                                    </div>
                                </div>
                                <div className="tab-pane fade show active" id="games" role="tabpanel" aria-labelledby="games-tab">
                                    <div >
                                        <GamesList items={publishersData} likedGameNavigation={true} publisherGame={true} />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}
