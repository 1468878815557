import API from "../utility/axios";

export const LOGIN = (data: any): Promise<any> => {
    return API.post(`/user`, data);
}
export const SIGNIN = (data: any): Promise<any> => {
    return API.post(`/user/login`, data);
}
export const Userlist = (): Promise<any> => {
    return API.get(`/user`);
}

export const UserUpdate = (data:any): Promise<any> => {
    return API.put(`/user/profile`,data);
}

export const UserDetailById = (userId:any): Promise<any> => {
    return API.get(`/user/profile/${userId}`);
}

