import React, { useState } from 'react';
import { Resources } from '../app/utility/resources';
import CoinDisplay from './coin-display';
import { Link, useNavigate } from 'react-router-dom';
import { connect } from 'react-redux';
import { PopupType } from '../app/redux/reducers/types';
import Profile from '../app/pages/profile';
import Notifications from '../pages/notifications';
// import MOLogo from './../assets/images/icons/moplay.png';

interface Props {
  userloginDetails?: any;
  showLoginPopup?: () => void;
}

const HeaderBar = (props: Props) => {
  const [canShowProfile, setCanShowProfile] = useState(false);
  const [canShowNotifications, setCanShowNotifications] = useState(false);

  const onProfile = () => {
    if (!props?.userloginDetails) {
      if (props.showLoginPopup) {
        props.showLoginPopup();
      }
    } else {
      // navigation(`/profile`)
      setCanShowProfile(true);
    }
  }

  return (
    <div>
      <div className='headerbar top-0 bg-light position-sticky shadow' style={{ zIndex: 1 }}>
        <label htmlFor="profile_toggler_checkbox"><img className='pointer' src={Resources.TOP_LEFT_HAMBURGER} alt="" onClick={onProfile} /></label>
        <Link to={'/'} style={{ display: 'contents' }}><img src={Resources.MOLOGO_PNG} alt="" /></Link>
        <Link className='ms-3' to={'/home'} style={{ display: 'contents' }}><img className='ms-3' width={40} src={Resources.GAMES_ICON} alt="" /></Link>
        <div className='coins d-flex align-items-center'>
          <CoinDisplay></CoinDisplay>
          <div onClick={() => setCanShowNotifications(true)} style={{ display: 'contents' }} className='ms-3'><img className='ms-3' src={Resources.NOTIFICATION_ICON_SVG} alt="" /></div>
          {props.userloginDetails && <div className='mx-3 d-none d-lg-block'>
            {props.userloginDetails.name}
          </div>}
        </div>
      </div>
      <Notifications canShow={canShowNotifications} onClose={() => setCanShowNotifications(false)}></Notifications>
      {/* <Profile canShowProfile={canShowProfile} onClose={() => setCanShowProfile(false)}></Profile> */}
    </div>
  )
}

const mapStateToProps = (state: any) => ({
  userloginDetails: state.UserStore.user
});
const mapDispatchToProps = (dispatch: any) => ({
  showLoginPopup: () => dispatch({ data: PopupType.LOGIN, type: 'popup' }),
})
export default connect(mapStateToProps, mapDispatchToProps)(HeaderBar);