import React from 'react'
import { StorageState } from '../redux/reducers/types'
import { connect } from 'react-redux';
import { User } from '../model/dto/res/user';
import HeaderBar from '../../components/header-bar';
import { BackBtnTitle } from '../components/back-btn-title';
interface Props {
    user?: User;
}
const Blog = (props: Props) => {
    return (
        <div className='bg-white' style={{ height: '99vh' }}>
            <div className="container">
                <BackBtnTitle text='Blogs' path='/'></BackBtnTitle>
                {props.user && <iframe style={{ height: `calc(99vh - 100px)` }} src={`https://blogs.mrdev.co.in/?client=66964c0822171c2c834c9c6a&mapped_id=${props.user._id}&name=${props.user.name}&profile_pic=${props.user.profile_img}`} width={'100%'}></iframe>}
            </div>
        </div>
    )
}

const mapStateToProps = (state: StorageState) => ({
    user: state.UserStore.user
});

export default connect(mapStateToProps)(Blog);