import { v4 } from "uuid";
import { JoinRoom } from "../../model/dto/socket/join-room";
import { MultiplayerParticipant } from "../../model/dto/socket/multiplayer-participant";
import { GamePlayType, MULITPLAYER_STATE, PlatformAction, PlatformActionType, PlatformScreen, PlatformStore, PopupType } from "./types";

const InitialState: PlatformStore = {
    active_screen: PlatformScreen.NONE,
    popupType: PopupType.IDLE,
    activeNotifications: [],
    msgNotifications: [],
    multiplayerState: MULITPLAYER_STATE.IDLE,
    multiplayerParticipants: [],
    gameType: GamePlayType.SINGLE_PLAYER,
    multiplayerUserGameData: null,
    multiplayerOpponentGameData: null,
    multiplayerBotData: null,
    activeGame: null,
    scrolledGameId: null,
    typingNotifications: [],
    msgDeliveryStatus: null,
    friends_status_refresh_id: null,
    initialShotIndex: 0
}

export const PlatformReducer = (state = InitialState, action: PlatformAction) => {
    console.log('got new actionnn', action);

    switch (action.type) {
        case PlatformActionType.POPUP:
            return { ...state, popupType: action.data };
            break;
        case PlatformActionType.LOGIN:
            return { ...state, popupType: action.data };
            break;
        case PlatformActionType.NEW_NOTIFICATION:
            {
                const updatedState = { ...state, popupType: PopupType.NOTIFICATION, activeNotifications: [...state.activeNotifications, action.data] };
                return updatedState;
            }
            break;
        case PlatformActionType.MSG_NOTIFICATION:
            {
                const updatedState = { ...state, popupType: PopupType.MSG_NOTIFICATION, msgNotifications: [...state.msgNotifications, action.data] };
                return updatedState;
            }
            break;

        case PlatformActionType.MSG_NOTIFICATION_CLEAR:
            {
                const emptyNotifs: string[] = [];
                const updatedState = { ...state, msgNotifications: emptyNotifs };
                return updatedState;
            }
            break;

        case PlatformActionType.MSG_TYPING_NOTIFICATION:
            {
                const previousNotes = (state.typingNotifications || []).filter(el => el !== action.data);
                previousNotes.push(action.data);
                const updatedState = { ...state, typingNotifications: [...previousNotes] };
                return updatedState;
            }
            break;

        case PlatformActionType.MSG_DELIVERY_STATUS:
            {
                try {
                    const msgDeliveryStatus = action.data;
                    return { ...state, msgDeliveryStatus: { ...msgDeliveryStatus } };
                } catch (error) {
                    console.log('errrrrrr ', error);

                }
            }
            break;
        case PlatformActionType.MSG_TYPING_NOTIFICATION_DELETE:
            {
                const filteredNotifications = state.typingNotifications.filter(el => el !== action.data);
                const updatedState = { ...state, typingNotifications: filteredNotifications };
                return updatedState;
            }
            break;

        case PlatformActionType.MULTIPLAYER_USER_JOINED:
            // case PlatformActionType.MULTIPLAYER_REQUEST_GAMEPLAY:
            {
                const participants = [...state.multiplayerParticipants];
                const newParticipant: JoinRoom = action.data;
                const existingParticipant = participants.find(el => el.id === newParticipant.user_id);
                if (!existingParticipant) {
                    participants.push({
                        id: newParticipant.user_id,
                        name: newParticipant.user_name,
                        profile_pic: newParticipant.profile_pic,
                        room_id: newParticipant.room_id
                    })
                    return { ...state, multiplayerParticipants: participants };
                }
                return state;
            }
            break;

        case PlatformActionType.MULTIPLAYER_REQUEST_GAMEPLAY:
            {
                const participants = [...state.multiplayerParticipants];
                const newParticipant: JoinRoom = action.data;
                const existingParticipant = participants.find(el => el.id === newParticipant.user_id);
                if (!existingParticipant) {
                    participants.push({
                        id: newParticipant.user_id,
                        name: newParticipant.user_name,
                        profile_pic: newParticipant.profile_pic,
                        room_id: newParticipant.room_id
                    })
                    return { ...state, multiplayerState: MULITPLAYER_STATE.CHECK_READYNESS, multiplayerParticipants: participants };
                } else if (state.multiplayerState !== MULITPLAYER_STATE.CHECK_READYNESS) {
                    return { ...state, multiplayerState: MULITPLAYER_STATE.CHECK_READYNESS };
                }
                return state;
            }
            break;

        case PlatformActionType.MULTIPLAYER_WAITING:
            {
                const userData: MultiplayerParticipant = action.data;
                const participants: MultiplayerParticipant[] = [userData];
                const updatedState: PlatformStore = { ...state, multiplayerParticipants: participants, gameType: GamePlayType.MULTIPLAYER };
                return updatedState;

            }
            break;

        case PlatformActionType.MULTIPLAYER_USER_DATA:
            return { ...state, multiplayerUserGameData: action.data };
            break;
        case PlatformActionType.MULTIPLAYER_GAME_DATA:
            return { ...state, multiplayerOpponentGameData: action.data }
            break;

        // case PlatformActionType.MULTIPLAYER_GAME_DATA:
        //     return { ...state, multiplayerOpponentGameData: action.data }
        //     break;

        case PlatformActionType.ACTIVE_GAME:
            return { ...state, activeGame: action.data }
            break;

        case PlatformActionType.MULTIPLAYER_GAME_END:
            return { ...state, multiplayerState: action.data.status === 1 ? MULITPLAYER_STATE.END : MULITPLAYER_STATE.OPPONENT_LEFT };
            break;

        case PlatformActionType.CLEAR_MULTIPLAYER:
            return {
                ...state,
                multiplayerState: MULITPLAYER_STATE.IDLE,
                multiplayerParticipants: [],
                gameType: GamePlayType.SINGLE_PLAYER,
                multiplayerUserGameData: null,
                multiplayerOpponentGameData: null,
                multiplayerBotData: null
            };
            break;

        case PlatformActionType.MULTIPLAYER_OPPONENT_EXIT:
            return {
                ...state, multiplayerOpponentGameData: action.data
            };
            break;

        case PlatformActionType.UPDATE_ACTIVE_SCREEN:
            return { ...state, active_screen: action.data };
            break;

        case PlatformActionType.MULTIPLAYER_BOT:
            return { ...state, multiplayerBotData: action.data, gameType: GamePlayType.MULTIPLAYER_BOT };
            break;

        case PlatformActionType.FRIENDS_LOGIN_CHECKS:
            return { ...state, friends_status_refresh_id: v4() }
            break;

        case PlatformActionType.INITIAL_VIDEO_SHOT:
            return { ...state, initialShotIndex: action.data }
            break;

        case PlatformActionType.SCROLLED_GAME:
            return { ...state, scrolledGameId: action.data };
            break;
        default:
            return state;
            break;
    }
}