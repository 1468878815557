import { NotificationRes } from "../model/notifcation.res";
import API from "../utility/axios";

export class NotificationService {
    static getNotifications(user: string): Promise<NotificationRes[]>{
        return API.get(`/notification/${user}`);
    }

    static open(id: string): Promise<NotificationRes[]>{
        return API.put(`/notification/open/${id}`);
    }
}