import React, { useEffect, useState } from 'react'
import Login from './login'
import { connect } from 'react-redux';
import { PopupType } from '../app/redux/reducers/types';
import MrAlert from '../app/components/mr-altert';
import { MrPopup } from '../app/components/mr-popup';
import { ChallengeInfo } from '../app/model/dto/res/challenge-info';
import { NotificationRes } from '../app/model/notifcation.res';
import { useNavigate, useNavigation } from 'react-router-dom';
import { GameService } from '../app/service/game.service';
import { ChatMsg } from '../app/model/dto/chat-msg';
import SignupPage from '../pages/signup-page';

interface Props {
    activePopup: PopupType,
    activeNotifications?: NotificationRes[];
    msgNotifications?: ChatMsg[];
    hideAllPopups: () => void;
}
const Popups = (props: Props) => {
    const [notificationContent, setNotificationContent] = useState('');
    const navigation = useNavigate();
    useEffect(() => {
        if (props.activeNotifications) {
            if (props.activeNotifications.length > 1) {
                setNotificationContent(`You have ${props.activeNotifications.length} new notifications`);
            } else if (props.activeNotifications[0]) {
                setNotificationContent(`${props.activeNotifications[0].user} ${props.activeNotifications[0].content}`);
            }
        }
    }, [props.activeNotifications]);

    useEffect(() => {
        if (props.msgNotifications && props.msgNotifications.length) {
            setNotificationContent(props.msgNotifications[props.msgNotifications.length - 1].content);
        }
    }, [props.msgNotifications]);
    useEffect(() => {
        console.log('props.......... ', props);

    }, [props])

    const onAcceptChallenge = async () => {
        if (props.activeNotifications?.length) {
            const notification = props.activeNotifications[props.activeNotifications?.length - 1];
            switch (notification.type) {
                case 'challenge':
                    const challegeData = await GameService.getChallenge(notification.notification_data);
                    navigation(`/play/${challegeData?.game}?challengeId=${challegeData?._id}`);
                    break;
                default:
                    break;
            }
        }
        props.hideAllPopups();
    }

    return (
        <div>
            {props.activePopup === PopupType.LOGIN && <Login></Login>}
            {props.activePopup === PopupType.SIGNUP && <SignupPage></SignupPage>}
            <MrAlert content={notificationContent} status={`${props.activePopup === PopupType.MSG_NOTIFICATION ? 'active' : 'idle'}`} ></MrAlert>
            {
                props.activePopup === PopupType.NOTIFICATION &&
                props.activeNotifications &&
                props.activeNotifications.length > 0 &&
                <MrPopup onClose={props.hideAllPopups} content={props.activeNotifications[props.activeNotifications.length - 1].content} heading={'New Challenge'}>
                    <button className="btn btn-primary" onClick={onAcceptChallenge}>Accept</button>
                </MrPopup>
            }
        </div>
    )
}

const mapStateToProps = (state: any) => ({
    activePopup: state.PlatformStore.popupType,
    activeNotifications: state.PlatformStore.activeNotifications,
    msgNotifications: state.PlatformStore.msgNotifications
});

const mapDispatchToProps = (dispatch: any) => ({
    hideAllPopups: () => dispatch({ data: PopupType.IDLE, type: 'popup' }),
});

export default connect(mapStateToProps, mapDispatchToProps)(Popups);
