import { faClose } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import React from 'react'
import { Resources } from '../utility/resources';

interface Props {
    heading: string;
    content: string;
    children?: any;
    onClose?: () => void;
}
export const MrPopup: React.FC<Props> = (props: Props) => {

    const closeLoginPopup = () => {

    }

    const stopPropagation = () => {

    }

    return (
        <div className="m-popup full-cover">
            <div className="m-popup-body justify-content-center moplay-corn-bg  align-items-center sx-bg-page position-relative " onClick={stopPropagation}>
                {/* <img src={Resources.POPUP_HEADER_IMAGE} onClick={props.onClose} className='w-100 position-absolute' style={{ top: -60, left: 0 }} /> */}
                <div className="popup-heading">
                    <h5 className='heading-label'>{props.heading}</h5>
                    <div className="close-btn" onClick={props.onClose}>
                        <img src={Resources.POPUP_CLOSE_ICON} alt="" />
                    </div>
                </div>
                <div className="m-popup-content">
                    <p>{props.content}</p>
                    {props.children}
                </div>
                {/* <div className="m-actions">
                </div> */}
                    {/* <button className="btn btn-primary">Accept</button> */}
            </div>
        </div>

    )
}
