import { DailyBonusStatus, PopupType, UserActionType, UserStore } from "./types";

const InitialState: UserStore = {
    user: null,
    popupType: PopupType.IDLE,
    dailyBonusStatus: DailyBonusStatus.PENDING
}

export const UserReducer = (state = InitialState, action: any) => {
    switch (action.type) {
        case 'login':
            if (action.user) {
                localStorage.setItem('userData', JSON.stringify(action.user));
            }
            const newState = { ...state, user: action.user, popupType: PopupType.IDLE };
            return newState;
        case UserActionType.LOGOUT:
            {
                localStorage.removeItem('userData');
                const user: any = null;
                const newState = { ...state, user };
                return newState;
            }
        case UserActionType.UPDATE_USER_PROFILE:
            {
                let user = { ...state.user, ...action.data };
                localStorage.setItem('userData', JSON.stringify(user));
                return { ...state, user };
            }
            break;
        case 'gamescore': {
            return { ...state, gamescore: action.gamescore };
        }

        case 'hidesidemenu': {
            return { ...state, hidesidemenu: action.hidesidemenu }
        }

        case 'popup': {
            return { ...state, popupType: action.data };
        }

        case UserActionType.SPEND_COINS:
            {
                let user = { ...state.user };
                if (user && user.wallet) {
                    let userWallet = { ...user.wallet };
                    userWallet.coins -= action.data;
                    user.wallet = userWallet;
                }

                localStorage.setItem('userData', JSON.stringify(user));
                return { ...state, user };
            }
            break;

        case UserActionType.SPEND_COINS:
            {
                let user = { ...state.user };
                if (user && user.wallet) {
                    let userWallet = { ...user.wallet };
                    userWallet.coins -= action.data;
                    user.wallet = userWallet;
                }

                localStorage.setItem('userData', JSON.stringify(user));
                return { ...state, user };
            }
            break;

        case UserActionType.COLLECT_DAILY_BONUS:
            {
                let user = { ...state.user };
                if (user && user.wallet) {
                    let userWallet = { ...user.wallet };
                    userWallet.coins += action.data;
                    user.wallet = userWallet;
                } else {
                    user.wallet = {
                        coins: action.data
                    };
                }
                localStorage.setItem('userData', JSON.stringify(user));
                return { ...state, user, dailyBonusStatus: DailyBonusStatus.COLLECTED };
            }
            break;
        default:
            return state;
    }
}