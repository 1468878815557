import { faArrowLeft } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React from 'react'
import { Link } from 'react-router-dom';

interface Props {
    text: string;
    path: string;
}

export const BackBtnTitle = (props: Props) => {
    return (
        <div className="px-3 mt-3 back-btn-title">
            <Link to={props.path} className='d-flex align-items-center'>
                <FontAwesomeIcon icon={faArrowLeft}></FontAwesomeIcon>
                <b className='ms-3'>{props.text}</b>
            </Link>
        </div>
    )
}
