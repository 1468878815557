import MOLOGO from '../../assets/images/text-icon.png';
import ICON_WHATSAPP from '../../assets/images/icons/social-whatsapp.png';
import COIN_IMG from '../../assets/images/icons/coin.png';
import TOP_LEFT_HAMBURGER from '../../assets/images/icons/hamburger.png';
import GAMES_ICON from '../../assets/images/icons/games-icon.png';
import NOTIFICATION_ICON_SVG from '../../assets/images/icons/notification.svg';
import POPUP_HEADER_IMAGE from '../../assets/images/pop-up-header-bg.png';
import POPUP_CLOSE_ICON from '../../assets/images/icons/close-icon.png';
import BONUS_COINS_PNG from '../../assets/images/bonus-coins.png';
import BONUS_COIN_PNG from '../../assets/images/coin.png';

export class Resources {
    static MOLOGO_PNG = MOLOGO;
    static COIN_PNG = COIN_IMG;
    static SOCIAL_ICON_WHATSAPP = ICON_WHATSAPP;
    static TOP_LEFT_HAMBURGER = TOP_LEFT_HAMBURGER;
    static GAMES_ICON = GAMES_ICON;
    static NOTIFICATION_ICON_SVG = NOTIFICATION_ICON_SVG;
    static POPUP_HEADER_IMAGE = POPUP_HEADER_IMAGE;
    static POPUP_CLOSE_ICON = POPUP_CLOSE_ICON;
    static BONUS_COINS_PNG = BONUS_COINS_PNG;
    static BONUS_COIN_PNG = BONUS_COIN_PNG;
} 