import React, { useEffect, useRef, useState } from 'react'
import Friends from '../components/freinds';
import { FindPeople } from '../components/find-people';
import { Invite } from '../components/invite';
import { PopupType } from '../app/redux/reducers/types';
import { connect } from 'react-redux';
import Header from '../components/header';
import HeaderBar from '../components/header-bar';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faAdd, faAddressBook, faSearch, faUserFriends } from '@fortawesome/free-solid-svg-icons';

interface Props {
    userdata: any;
    showLoginPopup?: () => void;
}

const Social = (props: Props) => {
    const [previousPosition, setPreviousPosition] = useState(0);
    const [activeElement, setActiveElement] = useState<string | number>(1);

    const loginPopupRef: any = useRef();

    const tabs = [
        {
            id: "1",
            title: "Friends",
            content: <Friends score={0} />,
            icon: faUserFriends
        },
        {
            id: "2",
            title: "Find People",
            content: <FindPeople />,
            icon: faSearch
        },
        {
            id: "3",
            title: "Invite",
            content: <Invite />,
            icon: faAdd
        },
    ]
    const [activeTab, setActiveTab] = useState(tabs[0].id);

    useEffect(() => {
        if (!props?.userdata?.user) {
            if (props.showLoginPopup) {
                props.showLoginPopup();
            }
        }
    }, []);


    const handleTabClick = (tabId: string) => {
        setActiveTab(tabId);
    };


    return (
        <>
            <div className={`mp-scroll-bar-width  position-relative container`} ref={loginPopupRef} style={{
                paddingBottom: 250
            }}>
                <div className='py-4'>
                    <ul className="d-flex ps-3" id="myTab" role="tablist" style={{listStyle: 'none'}}>
                        {tabs.map((tab, index) => (
                            <li className="nav-item" role="presentation" key={tab.id}>
                                <button
                                    className={`px-3 btn ${index>0?'ms-2': ''}  ${activeTab === tab.id ? 'btn-primary' : 'btn-outline-primary'}`}
                                    id={`${tab.id}-tab`} onClick={() => handleTabClick(tab.id)}
                                    data-bs-toggle="tab" type="button" role="tab"
                                    aria-controls={tab.id} aria-selected={activeTab === tab.id}
                                >
                                    {tab.title}
                                    <FontAwesomeIcon className='ms-1' icon={tab.icon}></FontAwesomeIcon>
                                </button>
                            </li>
                        ))}
                    </ul>

                    <div className="tab-content" id="myTabContent">
                        {tabs.map((tab) => (
                            <div
                                className={`tab-pane fade ${activeTab === tab.id ? 'show active' : ''}`}
                                id={tab.id} role="tabpanel" a-labelledby={`${tab.id}-tab`} key={tab.id}
                            >
                                {activeTab === tab.id && tab.content}
                            </div>
                        ))}
                    </div>
                </div>
            </div >
        </>
    )
}

const mapStateToProps = (state: any) => ({
    userdata: state.UserStore
});
const mapDispatchToProps = (dispatch: any) => ({
    showLoginPopup: () => dispatch({ data: PopupType.LOGIN, type: 'popup' }),
})
export default connect(mapStateToProps, mapDispatchToProps)(Social);
