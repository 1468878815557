import React from 'react'

interface Props {
  size: number;
  color: string;
}
export const CircleDot = (props:Props) => {
  return (
    <div style={{
      width: props.size, 
      height: props.size, 
      backgroundColor: props.color,
      borderRadius: '50%'
    }}></div>
  )
}
