import React, { useEffect, useState } from 'react'
import HeaderBar from '../components/header-bar'
import { connect } from 'react-redux';
import { Game } from '../app/model/dto/res/game';
import { MultiplayerParticipant } from '../app/model/dto/socket/multiplayer-participant';
import DisplayPicture from '../components/ui/display-picture';
import { MultiplayerGameSummary } from '../app/model/dto/socket/multiplayer-game-summary';
import { GamePlayType, MULITPLAYER_STATE, PlatformActionType } from '../app/redux/reducers/types';
import { useNavigate, useParams } from 'react-router-dom';
import { MultiplayerGameData } from '../app/model/dto/socket/multiplayer-game-data';
import { MultiplayerBot } from '../app/model/dto/res/multiplayer-bot';

interface Props {
    activeGame?: Game;
    participants?: MultiplayerParticipant[];
    multiplayerOpponentGameData?: MultiplayerGameData;
    multiplayerUserGameData?: MultiplayerGameData;
    clearMultiplayer?: () => void;
    multiplayerState: MULITPLAYER_STATE;
    multiplayerBotData: MultiplayerBot;
    gameType: GamePlayType;
}
const GameResultMultiplayer = (props: Props) => {
    let { id } = useParams<{ id: string }>();
    const [userData, setUserData] = useState<MultiplayerParticipant>(null);
    const [opponentData, setOpponentData] = useState<MultiplayerParticipant>(null);
    const [opponentGameData, setOpponentGameData] = useState<MultiplayerGameData>(null);
    const [winStatus, setWinStatus] = useState(0);
    const navigation = useNavigate();

    useEffect(() => {
        if (props.participants && props.participants) {
            setUserData(props.participants[0]);
            setOpponentData(props.participants[1]);
        }
        return () => {
        }
    }, [props.participants]);

    useEffect(() => {
        if (props.multiplayerUserGameData && props.multiplayerOpponentGameData) {
            setOpponentGameData(props.multiplayerOpponentGameData);
            if (props.multiplayerUserGameData.score > props.multiplayerOpponentGameData.score) {
                setWinStatus(1);
            } else if (props.multiplayerUserGameData.score < props.multiplayerOpponentGameData.score) {
                setWinStatus(2);
            } else {
                setWinStatus(0);
            }
        }
    }, [props.multiplayerUserGameData, props.multiplayerOpponentGameData]);

    useEffect(() => {
        if(props.gameType === GamePlayType.MULTIPLAYER_BOT && props.multiplayerBotData) {
            const {name, profile_pic, moves} = props.multiplayerBotData;
            setOpponentData({
                name, profile_pic, id: null, room_id: null
            });
            // const lastMove = moves[moves.length-1];
            const preparedData: MultiplayerGameData ={
                meta: null, score: moves[moves.length-1].score, status: 1, time: 0
            }
            setOpponentGameData(preparedData);

            if(props.multiplayerUserGameData) {

                if (props.multiplayerUserGameData.score > preparedData.score) {
                    setWinStatus(1);
                } else if (props.multiplayerUserGameData.score < preparedData.score) {
                    setWinStatus(2);
                } else {
                    setWinStatus(0);
                }
            }
        }
    }, [props.multiplayerBotData, props.multiplayerUserGameData])

    const goToGame = () => {
        if (props.clearMultiplayer) {
            props.clearMultiplayer();
        }

        navigation(`/detail/${id}`)
    }

    return (
        <div>
            <div className="mt-5">
                <h2 className="text-center border-bottom pb-2 ">{props.activeGame?.name}</h2>
            </div>
            <div className="d-flex justify-content-around align-items-center mt-5">
                {userData &&
                    <div className='text-center anim-left-to-right'>
                        <DisplayPicture bg_class='bg-info' first_name={userData.name} last_name='' profile_pic={userData.profile_pic}></DisplayPicture>
                        <p className='mt-3'>{userData.name}</p>
                        <p>{props.multiplayerUserGameData?.score}</p>
                    </div>
                }
                <h1>V/s</h1>
                <div>
                    {opponentData &&
                        <div className='text-center anim-right-to-left'>
                            <DisplayPicture bg_class='bg-success' first_name={opponentData.name} last_name='' profile_pic={opponentData.profile_pic}></DisplayPicture>
                            <p className='mt-3'>{opponentData.name}</p>
                            <p>{opponentGameData?.score || 0}</p>
                        </div>
                    }
                </div>
            </div>
            {
                winStatus === 1 &&
                <div className='text-center'>
                    <h4 className="title-reg">Congratulations...</h4>
                    <p>You won the match</p>
                </div>
            }
            {
                winStatus === 2 &&
                <div className='text-center'>
                    <p>You lost the match</p>
                </div>
            }
            <div className='text-center'>
                {props.multiplayerState === MULITPLAYER_STATE.WAITING_FOR_OPPONENT && <p className='title-reg'>Waiting for opponent to finish the game</p>}
                {props.multiplayerState === MULITPLAYER_STATE.OPPONENT_LEFT &&
                    <>
                        <p className='title-reg'>Opponent left</p>
                        <button className='btn btn-primary' onClick={goToGame}>Ok</button>
                    </>
                }
                {props.multiplayerState === MULITPLAYER_STATE.END && <button className='btn btn-primary' onClick={goToGame}>Ok</button>}
            </div>
        </div>
    )
}

const mapStateToProps = (state: any) => ({
    activeGame: state.PlatformStore.activeGame,
    participants: state.PlatformStore.multiplayerParticipants,
    multiplayerOpponentGameData: state.PlatformStore.multiplayerOpponentGameData,
    multiplayerUserGameData: state.PlatformStore.multiplayerUserGameData,
    multiplayerState: state.PlatformStore.multiplayerState,
    multiplayerBotData: state.PlatformStore.multiplayerBotData,
    gameType: state.PlatformStore.gameType,
});

const mapDispatchToProps = (dispatch: any) => ({
    clearMultiplayer: () => dispatch({ type: PlatformActionType.CLEAR_MULTIPLAYER })
})
export default connect(mapStateToProps, mapDispatchToProps)(GameResultMultiplayer);
