import React, { useEffect } from 'react'
import { StorageState } from '../app/redux/reducers/types';
import { Wallet } from '../app/model/dto/res/wallet';
import COIN_ICON from "./../assets/images/icons/coin.png";
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';

interface Props {
    wallet?: Wallet
}

const CoinDisplay: React.FC<Props> = (props: Props) => {
    return (
        <Link to={'/shop'} className='text-decoration-none'>
            <div className='align-items-center d-flex px-3 py-1 bg-white ' style={{borderRadius: 10}}>
                <img src={COIN_ICON} alt="" style={{ width: 16 }} />
                <b className="text-dark ms-2">{props.wallet?.coins || 0}</b>
            </div>
        </Link>
    )
}

const mapStateToProps = (state: StorageState) => ({
    wallet: state.UserStore.user?.wallet
});
export default connect(mapStateToProps)(CoinDisplay);