import React, { useEffect, useState } from 'react'
import { connect } from 'react-redux';
import { PlatformActionType } from '../redux/reducers/types';

interface Props {
    status: 'idle' | 'active';
    content: string;
}

const MrAlert: React.FC<Props> = (props: Props) => {
    const [currentStatus, setCurrentStatus] = useState('idle');

    useEffect(() => {
        if (currentStatus !== props.status) {
            setCurrentStatus(props.status);
            if (props.status === 'active') {
                setTimeout(() => {
                    setCurrentStatus('idle');
                }, 5000);
            }
        }
    }, [props])


    return (
        <div className={`${currentStatus} mr-alert`}>
            <div className='altert-content'>
                {props.content}
            </div>
        </div>
    )
}

const mapStateToProps = (state: any) => ({
});

const mapDispatchToProps = (dispatch: any) => ({
    clearNotifications: () => dispatch({ data: PlatformActionType.MSG_NOTIFICATION_CLEAR }),
});

export default connect(mapStateToProps, mapDispatchToProps)(MrAlert);
