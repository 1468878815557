import { User } from "../model/dto/res/user";
import { GoogleLogin } from "../model/user/google-login";
import API from "../utility/axios";

export class UserService {

    static loginWithGoogle(data: GoogleLogin): Promise<User> {
        return API.post(`/user/login/google`, data);
    }

    static getUserData(id: string): Promise<User> {
        return API.get(`/user/profile/${id}`);
    }

    static updateUserSession(user: string, socket: string, token: string): Promise<void> {
        return API.post(`/session`, { user, socket, token });
    }

    static playGame(game: string, type: number): Promise<void> {
        return API.post(`/user/play`, { game, type });
    }

    static getInfo(): Promise<User> {
        return API.get(`/user/me`);
    }

    static updateWallet(coins: number): Promise<User> {
        return API.post(`/wallet`, { coins });
    }
}