import React, { useEffect, useRef, useState } from 'react'
import { SocketSvc } from '../app/service/socket.service';
import { connect } from 'react-redux';
import { User } from '../app/model/dto/res/user';
import { ChatMsg } from '../app/model/dto/chat-msg';
import { ChatService } from '../app/service/chat.service';
import { Dispatch } from 'redux';
import { MsgDeliveryStatus, PlatformActionType } from '../app/redux/reducers/types';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCheck, faCheckDouble } from '@fortawesome/free-solid-svg-icons';
import { v4 } from 'uuid';
import moment from 'moment';

interface Props {
    friend?: User;
    closeChat?: any;
    challengeSection?: any;
    userloginDetails: User;
    msgNotifications?: ChatMsg[];
    typingNotifications?: string[];
    updateTypingNote?: (data: string) => void;
    msgDeliveryStatus?: MsgDeliveryStatus;
}

const Chat = (props: Props) => {
    const [message, setMessage] = useState('');
    const [messages, setMessages] = useState<ChatMsg[]>([]);
    const [showTypingIndication, setShowTypingIndication] = useState(false);
    const chatboxRef = useRef(null);

    useEffect(() => {
        loadChatHistory();
    }, [])

    const loadChatHistory = async () => {
        const chatHistory = await ChatService.getHistory(props.userloginDetails._id, props.friend?._id || '');
        const previousMsgs: ChatMsg[] = chatHistory.map(el => {
            return {
                content: el.content,
                sender_id: el.sender,
                chat_id: '',
                status: el.status,
                msg_id: el.msg_id,
                created_dt: new Date(el.created_dt).toString()
            }
        });
        const unreadMsgs: MsgDeliveryStatus[] = previousMsgs.filter(el => el.status <= 1).map(el => {
            return { id: el.msg_id, status: 2 }
        });
        SocketSvc.sendMsgStatus(props.friend._id, unreadMsgs);
        setMessages(previousMsgs);
        scrollToBottom();
    }
    // useEffect(() => {
    //     if (props.msgNotifications?.length) {
    //         const latestMsg = props.msgNotifications[props.msgNotifications.length - 1];
    //         const previousMsgs = [...messages];
    //         if (props.friend?._id === latestMsg.sender_id) {
    //             previousMsgs.push(latestMsg);
    //         }
    //         const unreadMsgs: MsgDeliveryStatus[] = previousMsgs.filter(el => el.status <= 1).map(el => {
    //             return { id: el.msg_id, status: 2 }
    //         });
    //         SocketSvc.sendMsgStatus(props.friend._id, unreadMsgs);
    //         setMessages(previousMsgs);
    //     }
    // }, [props.msgNotifications]);

    useEffect(() => {
        if (props.msgNotifications?.length) {
            const latestMsg = props.msgNotifications[props.msgNotifications.length - 1];
            const previousMsgs = [...messages];
            if (props.friend?._id === latestMsg.sender_id) {
                previousMsgs.push({ ...latestMsg });
            }
            const unreadMsgs: MsgDeliveryStatus[] = previousMsgs.filter(el => el.status <= 1).map(el => {
                return { id: el.msg_id, status: 2 }
            });

            SocketSvc.sendMsgStatus(props.friend._id, unreadMsgs);
            setMessages(previousMsgs);
            scrollToBottom();
        }
    }, [props.msgNotifications]);

    useEffect(() => {
        try {

            const msgs = [...messages];
            if (props.msgDeliveryStatus?.id) {
                const msgIndex = msgs.findIndex(el => el.msg_id === props.msgDeliveryStatus.id);
                for (let index = 0; index <= msgIndex; index++) {
                    const element = msgs[index];
                    if (element && element.status < props.msgDeliveryStatus.status) {
                        element.status = props.msgDeliveryStatus.status;
                    }
                }

                setMessages(msgs);
            }
        } catch (error) {
            console.log('error while updatinggg ', error);

        }
    }, [props.msgDeliveryStatus]);

    useEffect(() => {
        const timer = setTimeout(() => {
            setShowTypingIndication(false);
        }, 1000);
        if (props.typingNotifications && props.typingNotifications.length > 0) {
            const typingNot = props.typingNotifications.find(el => el === props.friend._id);
            if (typingNot.length) {
                setShowTypingIndication(true);
            }
        }
        return () => {
            clearTimeout(timer);
        }
    }, [props.typingNotifications]);

    const sendMessage = () => {
        if (message.trim()) {
            const msg_id = v4();
            setMessages([...messages, {
                chat_id: props.friend?._id || '',
                sender_id: props.userloginDetails._id,
                content: message,
                msg_id,
                status: 0,
                created_dt: new Date().toString()
            }]);
            setMessage('');
            SocketSvc.sendMsg(props.friend?._id as string, message, props.userloginDetails._id, msg_id, props.userloginDetails.name);
            scrollToBottom();
        }
    };

    const handleClose = () => {
        props?.closeChat(true)
    }

    const sendTypingNote = () => {
        // if (props.updateTypingNote) {
        //     props.updateTypingNote(props.friend._id);
        // }
        if (props.friend) {
            SocketSvc.sendTypingNote(props.userloginDetails._id, props.friend._id);
        }
    }

    const scrollToBottom = () => {
        setTimeout(() => {
            if (chatboxRef) {
                const chatboxContainer = chatboxRef.current as HTMLDivElement;
                chatboxContainer.scrollTop = chatboxContainer.scrollHeight + 100;
            }
        }, 100);
    }
    return (
        <div className="chat-screen">
            <div className="header">
                <div className="friend-info">
                    {props?.friend?.profile_img ? (
                        <img src={props?.friend.profile_img} alt={props?.friend.name} className="friend-profile-img" />
                    ) : (
                        <span className="moplay-primary-bg text-white rounded-circle py-1 d-inline-block text-center mp-user-profile-icon">
                            {props?.friend?.name.charAt(0).toUpperCase()}
                        </span>
                    )}
                    <div className="friend-details mx-2">
                        <div className="friend-name">{props?.friend?.name}</div>
                        <div className="friend-address">{props?.friend?.address}</div>
                    </div>
                </div>
                <button className="close-btn" onClick={handleClose}>Close</button>
            </div>
            <div className="messages-container mb-4" ref={chatboxRef}>
                {messages.map((msg, index) => (
                    <div key={index} className={`position-relative message d-flex align-items-center ${msg.sender_id === props.userloginDetails._id ? 'sent' : 'received'}`}>
                        <div className="message-content pb-3">{msg.content}</div>
                        <div className="position-absolute pe-2" style={{ right: 5, bottom: 5 }}>
                            <small className='me-2 timing'>{moment(msg.created_dt).format('hh:MM A DD-MMM-yyyy')}</small>
                            {msg.sender_id === props.userloginDetails._id && <>
                                {msg.status === 0 && <span>&#x2713;</span>}
                                {msg.status >= 1 &&
                                    <>
                                        <span className={`${msg.status === 2 ? 'seen' : ''} indicator`}>&#x2713;</span>
                                    </>
                                }
                            </>
                            }
                        </div>
                    </div>
                ))}
            </div>
            {props?.challengeSection && (
                <div className='message-container mb-1'>
                    Challenge coins
                </div>
            )}
            <div className="input-container position-relative">
                {showTypingIndication && <i className="ms-3 position-absolute" style={{ bottom: '100%', color: '#1da700' }}>Typing...</i>}
                <input
                    type="text"
                    className="message-input"
                    placeholder="Type a message..."
                    value={message}
                    onChange={(e) => setMessage(e.target.value)}
                    onKeyDown={(e) => {
                        if (e.key === 'Enter') {
                            sendMessage();
                        } else {
                            sendTypingNote();
                        }
                    }}
                />
                <button className="send-button" onClick={sendMessage}>Send</button>
            </div>
        </div>
    )
}

const mapStateToProps = (state: any) => ({
    userloginDetails: state.UserStore.user,
    msgNotifications: state.PlatformStore.msgNotifications,
    typingNotifications: state.PlatformStore.typingNotifications,
    msgDeliveryStatus: state.PlatformStore.msgDeliveryStatus,
});

const mapDispatchToProps = (dispatch: Dispatch) => ({
    updateTypingNote: (data: string) => dispatch({ type: PlatformActionType.MSG_TYPING_NOTIFICATION, data })
});

export default connect(mapStateToProps, mapDispatchToProps)(Chat);
