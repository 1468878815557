import axios from 'axios';
import appConfig from '../../config/constant';
import { ReduxStore } from '../redux/store';
import { User } from '../model/dto/res/user';

const API = axios.create({
    baseURL: appConfig.BASE_URL
});


API.interceptors.request.use(function (config) {
    const user: User = ReduxStore.getState()?.UserStore?.user;
    if (user) {
        config.headers.Authorization = user.token;
    }
    config.headers['Content-Type'] = 'application/json';
    // config.headers['multipart/form-data'] = 'application/json';
    return config;
});

API.interceptors.response.use(
    (response) => {
        return response.data;
    }, async (error) => {
        if(error.code === 'ERR_NETWORK') {
            throw 'Internet not available';
        }
        throw error.response.data;
    }
);
export default API;