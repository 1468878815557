import { GamePlayType } from "../redux/reducers/types";
import API from "../utility/axios";

export class GameStats {
    static uploadStats(game_id: string, game_type: GamePlayType): Promise<string> {
        return API.post(`/gamestats`, { game_id, game_type });
    }

    
    static updateStats(_id: string, play_time: GamePlayType): Promise<string> {
        return API.put(`/gamestats/${_id}`, { play_time });
    }
}

export const GameLeaderBoradDetails = (data: any): Promise<any> => {
    return API.get(`/scores/${data}`);
}