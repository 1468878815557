import React, { SyntheticEvent, useEffect, useState } from 'react'
import { SocialService } from '../app/service/social.service';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCircleCheck } from '@fortawesome/free-solid-svg-icons';
import { User } from '../app/model/dto/res/user';

export const FindPeople = () => {
    const [peopleData, setPeopleData] = useState<User[]>([]);
    const [filteredFriends, setFilteredFriends] = useState<any[]>([]);
    const [showFriendPopup, setShowFriendPopup] = useState<boolean>(false);
    const stopPropagation = (event: any) => { event.stopPropagation(); }
    const [followStatus, setFollowStatus] = useState<boolean>(false);
    const [showInviteConfirmationPopup, setShowInviteConfirmationPopup] = useState<boolean>(false);
    const [invitedFreindData, setInvitedFreindData] = useState<any>({});
    const userData: any = localStorage?.getItem("userData");
    const parsedUserData = JSON.parse(userData);

    useEffect(() => {
        loadPeople();
    }, [])

    const loadPeople = () => {
        SocialService.GetPeople(parsedUserData?._id).then((res: any) => {
            const filteredRes = res?.filter((el: any) => el?.name !== null && el?.address !== null)
            setPeopleData(filteredRes);
            setFilteredFriends(filteredRes);
        })
    }

    const handleFollowStatus = async (data: any) => {
        setFollowStatus(true);

        const followPayload: any = {
            from: parsedUserData?._id,
            to: data?._id
        }
        await SocialService.GetFollowStatus(followPayload);
        const friendData = filteredFriends.find(el=>el._id === data._id);
        if(friendData) {
            friendData.isFriend = true;
        }
        setFilteredFriends([...filteredFriends]);
    }

    const onChangeSearchHere = (event: SyntheticEvent) => {
        const inputEl = event.target as HTMLInputElement;
        const searchedList = peopleData.filter(el => el.name.toLocaleLowerCase().indexOf(inputEl.value.toLowerCase()) > -1);
        setFilteredFriends(searchedList);
    }

    return (
        <div className='rounded-3 text-dark py-2'>
            <div style={{ paddingBottom: 100 }}>
            <div className="px-3">
                <input type="text" className="form-control" placeholder='Search friend here' onChange={onChangeSearchHere} />
            </div>
                {filteredFriends.map((data: any, index: number) => (
                    <div className='m-2' key={index}>
                        <div className='border-bottom py-2 px-2'>
                            <div className='d-flex justify-content-between'>
                                <div>
                                    <div className='d-flex align-items-center'>
                                        <div>
                                            {data?.profile_img ?
                                                <img src={data?.profile_img} className='mp-friend-profile-img rounded-circle' />
                                                : <span className='moplay-primary-bg text-white rounded-circle py-1 d-inline-block text-center mp-user-profile-icon'>
                                                    {data?.name?.charAt(0)?.toUpperCase()}
                                                </span>}
                                        </div>
                                        <div>
                                            <strong className='mx-2'>{data?.name === null ? "" : data?.name}</strong>
                                            <div className='mx-2 f-10'>{data?.address}</div>
                                        </div>
                                    </div>
                                </div>
                                <div className='d-flex moplay-lemon-clr align-items-center'>
                                    <button className={` py-1 ${data?.followStatus || data?.isFriend ? "bg-transparent fw-bold border-0 mp-cursor-pointer-not-allowed" : "btn btn-primary mp-cursor-pointer"}`} disabled={data?.isFriend} onClick={(e: any) => handleFollowStatus(data)}>
                                        <span className='me-1'>{data?.followStatus || data?.isFriend ? <FontAwesomeIcon icon={faCircleCheck} /> : <></>}</span>
                                        <span>{data?.followStatus || data?.isFriend ? "Following" : "Follow"}</span>
                                    </button>
                                </div>
                            </div>
                        </div>
                    </div>
                ))}
                {peopleData?.length == 0 && (
                    <div className='text-center my-5 align-items-center'>You don't have people</div>
                )}
            </div>
        </div>
    )
}
