import React, { useEffect, useState } from 'react'
import { Link, NavLink, useNavigate, useParams } from 'react-router-dom'
import { GameById } from '../app/service/game.service';
import { LeaderBoard } from '../components/leader-board';
import { GameLeaderBoradDetails } from '../app/service/gamestats.service';
import { GAME_URL } from '../config/constant';
import { ScoreService } from '../app/service/score.service';
import { connect } from 'react-redux';
import Friends from '../components/freinds';
import Chat from '../components/chat';
import UserProfileImage from './../assets/images/user-profile.jpeg';
import { User } from '../app/model/dto/res/user';
import { ChallengeService } from '../app/service/challenge.service';
import { ChallengeInfo } from '../app/model/dto/res/challenge-info';
import { ChallengeSubmitReq } from '../app/model/dto/req/challenge-submit.req';
import HeaderBar from '../components/header-bar';
import DisplayPicture from '../components/ui/display-picture';
import { PlatformActionType } from '../app/redux/reducers/types';
import { Resources } from '../app/utility/resources';


interface Props {
    scoredata?: any;
    user?: User;
    clearMultiplayer?: () => void;
}

const GameResult: React.FC = (props: Props) => {
    let { id } = useParams<{ id: string }>();
    const gameId = id as string;
    const url = new URL(window.location.href);
    const challengeId = url.searchParams.get('challengeId');
    const gameSubmissionType = Number(url.searchParams.get('type'));
    const [gameData, setGameData] = useState<any>([]);
    const [leaderBoardData, setLeaderBoardData] = useState<any>([]);
    const [showScore, setShowScore] = useState(false);
    const [userMaxScore, setUserMaxScore] = useState<any>(null);
    const [showFriendsList, setShowFriendsList] = useState<any>(gameSubmissionType === 1);
    // const [showChallengeSection, setShowChallengeSection] = useState<any>(gameSubmissionType === 1);
    const [challengeData, setChallengeData] = useState<ChallengeInfo>();
    const [userScore, setUserScore] = useState(0);
    const [opponentScore, setOpponentScore] = useState(0);
    const navigation = useNavigate();

    useEffect(() => {
        gameDetailsById(gameId);
        loadLeaderBoardDetails(gameId);
        setShowScore(true);
        loadHighScore();
        if (challengeId) {
            loadChallengeData();
            submitChallenge();
        }
    }, []);

    const gameDetailsById = (data: any) => {
        const gameId = data;
        GameById(gameId).then((res: any) => {
            setGameData(res)
        })
    }

    const loadLeaderBoardDetails = (data: any) => {
        GameLeaderBoradDetails(data).then((res: any) => {
            setLeaderBoardData(res)
        })
    }

    const loadHighScore = async () => {
        if (props.user) {
            const userMaxScore = await ScoreService.getMyScore(gameId, props.user._id);
            setUserMaxScore(userMaxScore);
        }
    }

    const handleChallenge = () => {
        setShowFriendsList(!showFriendsList);
    }

    // const getShowChallege = (status: any) => {
    //     if (status) {
    //         setShowChallengeSection(true);
    //     }
    // }

    const loadChallengeData = async () => {
        const challengeData = await ChallengeService.getChallengeInfo(challengeId as string);
        console.log('challenge data ', challengeData);
        if (challengeData && challengeData[0]) {
            setChallengeData(challengeData[0]);
            setUserScore(props?.scoredata?.score);
            setOpponentScore(challengeData[0].score);
        }
    }

    const submitChallenge = async () => {
        const data: ChallengeSubmitReq = {
            challengeId: challengeId as string,
            score: props?.scoredata?.score || 0
        };
        await ChallengeService.submitChallenge(data);
    }

    const onOpenMultiplayer = () => {
        {/* <Link to={`/waiting/${gameId}`}></Link> */ }
        if (props.clearMultiplayer) {
            props.clearMultiplayer();
        }
        navigation(`/waiting/${gameId}`);
    }

    return (
        <div className='container'>
            {
                gameSubmissionType !== 1 && <div className='container-fluid'>
                    <div className='row mx-auto d-flex flex-column align-items-center'>
                        <div className='col-md-12 my-3 '>
                            <h2 className='text-center fs-1 fw-bold pb-2 border-bottom'>{gameData?.name}</h2>
                            {!challengeId && (<>
                                <div className={`score-container ${showScore ? 'show' : ''}`}>
                                    <div className='d-flex justify-content-center my-3'>
                                    </div>
                                </div>
                                <div className='d-flex justify-content-center my-3'>
                                </div>
                                <div className='d-flex flex-column justify-content-center my-3'>
                                    <p>Your score : {props?.scoredata?.score || 0}</p>
                                    <p>High score : {userMaxScore && userMaxScore.score}</p>
                                    <div className="d-flex d-none d-lg-block">
                                        <Link className='mb-3' to={`/play/${gameId}`}><button className='btn btn-outline-primary'>Play again</button></Link>
                                        <button className='ms-2 btn btn-primary' onClick={handleChallenge}>{showFriendsList ? "Leader Board" : "Challege"}</button>
                                        <button className='ms-2 btn btn-outline-primary' onClick={onOpenMultiplayer}>Multiplayer</button>
                                    </div>
                                    <div className="d-lg-none">
                                        <Link className='w-100 mb-3' to={`/play/${gameId}`}><button className='w-100 btn btn-outline-primary'>Play again</button></Link>
                                        <div className='d-flex'>
                                            <button className='w-100 me-2 btn btn-primary' onClick={handleChallenge}>{showFriendsList ? "Leader Board" : "Challege"}</button>
                                            <button className='w-100 btn btn-outline-primary' onClick={onOpenMultiplayer}>Multiplayer</button>
                                        </div>
                                    </div>
                                </div>
                                <div className='d-flex justify-content-center my-3'>
                                </div>
                            </>
                            )}

                            {challengeId && (
                                <div className='shadow col-md-6 mx-auto rounded-3 position-relative'>
                                    {/* <img src={Resources.WIN_EFFECT_GIF} alt="" className='position-absolute' /> */}
                                    <div className='px-lg-5 pb-3 d-flex justify-content-center my-2'>
                                        <div className='d-flex align-items-center flex-column w-100 text-white py-4 anim-left-to-right'>
                                            <DisplayPicture first_name={props.user?.name || ''} last_name='' profile_pic={props.user?.profile_img}></DisplayPicture>
                                            <h5 className='title-reg mt-3'>You</h5>
                                            <h4>{props?.scoredata?.score || 0}</h4>
                                        </div>
                                        {/* <div className='d-flex align-items-center fw-bold mx-5 '><span className='fs-3 '>V</span><span className='fs-4 '>s</span></div> */}
                                        <div className='d-flex align-items-center flex-column w-100 py-4 anim-right-to-left'>
                                            <DisplayPicture first_name={challengeData?.challenger[0].name || ''} last_name='' profile_pic={challengeData?.challenger[0].profile_img}></DisplayPicture>
                                            <h5 className='title-reg mt-3'>{challengeData?.challenger[0].name}</h5>
                                            <h4>{challengeData?.score || 0}</h4>
                                        </div>
                                    </div>
                                    {(userScore > opponentScore) && <div className='text-center fw-bold f-18 pb-3 px-5'>Congratulations! You won the challenge. you earned the bet amount <b className='text-info f-25'>{challengeData?.bet_amount}</b> coins.</div>}
                                    {(userScore < opponentScore) && <div className='text-center fw-bold f-18 pb-3 px-5'>You lost the game.</div>}
                                </div>
                            )}
                        </div>
                    </div>
                </div>
            }
            <div className='container-fluid overflow-hidden pb-5'>
                {!challengeId && (<div className={`${showFriendsList ? 'to-left' : 'idle mb-5 pb-5'} transition-500ms w-100`}>
                    {/* <div className="moplay-red-bg" style={{ height: 50 }}></div> */}
                    <LeaderBoard leaderBoardData={leaderBoardData} type={gameData?.leaderboard_type}/>
                </div>)}
                <div className={`${showFriendsList ? 'idle' : 'to-right'} transition-500ms w-100 mt-3`}>
                    <h2 className='text-center'>Challenge Friends</h2>
                    <Friends score={props?.scoredata?.score || 0} hideButtons={true} gameUserId={gameId} />
                    <div className="text-center pb-5">
                        <NavLink to={`/detail/${gameId}`}><button className="btn btn-primary">Done</button></NavLink>
                    </div>
                </div>
                {/* {showChallengeSection && (
                    <Chat challengeSection={true} />
                )} */}
            </div>
        </div>
    )
}

const mapStateToProps = (state: any) => ({
    store: state,
    scoredata: state.UserStore.gamescore,
    user: state.UserStore.user
});

const mapDispatchToProps = (dispatch: any) => ({
    clearMultiplayer: () => dispatch({ type: PlatformActionType.CLEAR_MULTIPLAYER })
})

export default connect(mapStateToProps, mapDispatchToProps)(GameResult);
