import React, { useEffect, useRef, useState } from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faThumbsUp, faPlay, faShare, faClose, faArrowUp, faArrowUpLong } from '@fortawesome/free-solid-svg-icons';
import { useNavigate } from 'react-router-dom';
import ICON_MOPLAY from '../assets/images/icons/moplay.png';
import { StatusGames } from '../app/service/statusgame.service';
import { GAME_URL } from '../config/constant';
import { GameService } from '../app/service/game.service';
import { connect } from 'react-redux';
import { PlatformActionType, PopupType } from '../app/redux/reducers/types';


interface Props {
    elementId?: string | number;
    data?: any;
    searchFindValue?: any;
    userloginDetails?: any;
    loginstatus?: any;
    LoginUserStatus?: any;
    publisherSwipeModalStatus?: any;
    duration?: any;
    progress?: any;
    type?: 'ad' | '';
    updateUserLoginStatus?: () => void;
    canScrollToTop: boolean;
    onScrollToTop: () => void;
    isInView: boolean;
}

const VideoShot: React.FC<Props> = (props: Props) => {
    const [videosData, setVideosData] = useState<any>([]);
    const [isLikeClick, setisLikeClick] = useState(true);
    const [likeStatus, setLikeStatus] = useState<boolean>(false);
    const navigation: any = useNavigate();
    const [showShareModal, setShowShareModal] = useState<any>(false);
    const [publisherData, setPublisherData] = useState<any>();
    const [shareItem, setShareItem] = useState<any>()
    const [isAnimating, setIsAnimating] = useState(false);
    const [showCopyButton, setShowCopyButton] = useState(false);
    const [videoShotUrl, setVideoShotUrl] = useState('');
    const [isPlaying, setIsPlaying] = useState<boolean>(true);
    const [showPauseButton, setShowPauseButton] = useState<boolean>(true);
    const [expanded, setExpanded] = useState<boolean>(false);
    const [currentTime, setCurrentTime] = useState(0);
    const [duration, setDuration] = useState(0);
    const progress = (currentTime / duration) * 100;
    const videoRef = useRef<HTMLVideoElement>(null);

    useEffect(() => {
        setVideosData(props.data);
        if (props?.data?.video) {
            const videoUrlstr: string = props.data.video;
            if (videoUrlstr.startsWith('http')) {
                setVideoShotUrl(`${props.data.video}`);
            } else {
                setVideoShotUrl(`${GAME_URL}${props.data.video}`);
            }
        }
        if (props.data) {
            setLikeStatus(props.data.is_liked);
        }
    }, [props.data, props.searchFindValue])

    useEffect(() => {

        setTimeout(() => {
            setShowCopyButton(true);
        }, 2000);
        const userData = localStorage.getItem("userData");
        if (userData) {
            const parsedUserData = JSON.parse(userData);
        }

        // (window as any).loadMonetagAd('saptorge.com', 7738894, document.createElement('script'))
    }, []);

    useEffect(() => {
        const video: any = videoRef.current;
        const handleTimeUpdate = () => {
            if (video) {
                // props?.videoCurrentTime(video?.currentTime);
                props?.duration(video?.duration);
                props?.progress(progress);
                setCurrentTime(video.currentTime);
                setDuration(video.duration);
            }
        };
        video?.addEventListener('timeupdate', handleTimeUpdate);
        return () => {
            video?.removeEventListener('timeupdate', handleTimeUpdate);
        };
    }, [videoRef.current]);

    useEffect(() => {
        const video = videoRef.current;
        if (video) {
            if (isPlaying) {
                video.play();
            } else {
                video.pause();
            }
        }
    }, [isPlaying]);

    const togglePlayPause = () => {
        setIsPlaying(prevState => !prevState);
        // Show or hide the pause button based on the isPlaying state
        setShowPauseButton(prevState => !prevState);
    };

    const incrementLikes = async (item: any, index: number) => {
        // Check if the user is logged in
        if (props?.userloginDetails == null || props?.userloginDetails == "undefined") {
            // User is not logged in, show login popup
            if (props.updateUserLoginStatus) {
                props.updateUserLoginStatus();
            }
        } else {
            // User is logged in
            // setuserFlowAction(false);
            const data: any = {
                game_id: item?._id,
                user_id: props?.userloginDetails?._id,
                type: likeStatus ? 'dislike' : 'like'
            };
            await GameService.updateGameLike(data);
            setIsAnimating(true);
            const likingStauts = !likeStatus;
            setLikeStatus(likingStauts);
            setTimeout(() => {
                setIsAnimating(false);
            }, 500);
        }
    };

    const incrementShares = (item: any, index: number) => {
        if (props?.userloginDetails == null || props?.userloginDetails == "undefined") {
            if (props.updateUserLoginStatus) {
                props.updateUserLoginStatus();
            }
        }
        else {
            setShowShareModal(true);
            setShareItem(item);
        }
    }

    const onClickPlayButton = (data: any) => {
        if (!props?.userloginDetails) {
            if (props.updateUserLoginStatus) {
                props.updateUserLoginStatus();
            }
        } else {
            navigation(`/detail/${props?.data?._id}`)
        }
    }

    const onClickPublisher = (data: any) => {
        setPublisherData(data);
        // setShowPublisherPageModal(true);
        props?.publisherSwipeModalStatus(true);
        // navigation(`/publisher/${data._id}`)
    }

    const onDescriptionView = (e: any) => {
        setExpanded(!expanded);
    }

    const onShareOnTwitter = () => {
        const urlToShare = `${window.location.host}/detail/${props.data._id}`;
        window.open(`http://twitter.com/intent/tweet?=${props.data.name}&url=${urlToShare}&hashtags=hashtag1,hashtag2,hashtag3`, '_blank', 'popup=true,width=500,height=500');
    }

    return (
        <>
            {
                props.type === 'ad' && <div className='d-flex align-items-center justify-content-center h-100 ad-slot'>
                    <a href='https://almstda.tv/4/7741291' className='text-center' target='_blank'>
                        Advertisement
                        {/* <iframe style={{ pointerEvents: 'none' }} src="https://almstda.tv/4/7741291"></iframe> */}
                    </a>
                    {/* <h1 style={{ color: 'lightgray', fontSize: 50 }}>Ad goes here</h1> */}
                    {/* <ins className="adsbygoogle"
                        style={{ display: 'block' }}
                        data-ad-format="fluid"
                        data-ad-layout-key="-6t+ed+2i-1n-4w"
                        data-ad-client="ca-pub-6421422503219653"
                        data-ad-slot="5778704896"></ins> */}
                    {/* <div id="container-1ef445372f939ed035be83664121e056"></div> */}
                    {/* <iframe src="https://www.highcpmgate.com/x1sqv79qb?key=6d2a01fc02c26674f836d40abd845de1" style={{width: '75%', height: '75%'}}></iframe> */}
                </div>
            }
            {props.type !== 'ad' && <div className='shot-player position-relative' >
                <video playsInline controlsList="nofullscreen" loop autoPlay muted className='shot-video' ref={videoRef} onClick={togglePlayPause} >
                    {props.isInView && videoShotUrl && <source src={videoShotUrl} type="video/mp4" />}
                </video>

                {/* Show play button when video is playing */}
                {!showPauseButton && (
                    <div className={`position-absolute top-0 d-flex flex-column justify-content-center align-items-center h-100 p-2 text-white moplay-promo-video-play-button-position ${isPlaying ? 'visible' : 'visible'}`}>
                        <button className='text-white action-btn border-0 fa-solid moplay-secondary-bg' onClick={togglePlayPause}>
                            <FontAwesomeIcon icon={faPlay} className=' text-center mx-auto float-center d-flex justify-content-center ' />
                        </button>
                    </div>
                )}
                <div className='position-absolute p-2 text-white video-info w-100'>
                    {
                        props.canScrollToTop && <div className="d-flex justify-content-center">
                            <span className="action-btn-container" onClick={props.onScrollToTop}>
                                <FontAwesomeIcon icon={faArrowUpLong} />
                            </span>
                        </div>
                    }
                    <div className='mb-2'><span className='me-2 mp-cursor-pointer'><img src={ICON_MOPLAY} className='mp-app-icon' onClick={() => onClickPublisher(props?.data)} /></span><span className='title-reg'>{props?.data.name}</span></div>
                    {props?.data?.description?.length > 80 ? (
                        <div className="mp-cursor-pointer fw-normal" onClick={onDescriptionView} title={props?.data?.description}>
                            {expanded ? props?.data?.description : props?.data?.description.substring(0, 80) + " ..."}
                        </div>
                    ) : (
                        <div className="fw-normal" title={props?.data?.description}>
                            {props?.data?.description}
                        </div>
                    )}
                </div>
                {/* <p className='position-relative bottom-0 p-2 text-white'></p> */}
                <div className="position-absolute top-0 d-flex flex-column justify-content-center align-items-center h-100 ps-2 pe-1 end-0 text-white">
                    {isLikeClick &&
                        <div className={`mb-4 action-btn-container`} onClick={() => incrementLikes(props?.data, props?.data?._id)} style={{
                            backgroundColor: likeStatus ? 'red' : ''
                        }}>
                            {/* <i className={`fa-solid fa-thumbs-down action-btn ${likeStatus || isAnimating || likeResData || props?.data?.is_liked ? "text-primary bg-white p-0" : "text-white"}`}>
                                {isAnimating && <div className="like-animation"></div>}
                            </i> */}
                            <FontAwesomeIcon icon={faThumbsUp} className={`like-button  ${isAnimating ? "heartbeat" : ""}`} />
                            {/* <p className={`text-white title-reg text-center mb-0`}>
                                {isNaN(props?.data?.total_likes) ? 0 : likes_format(props?.data?.total_likes)}
                            </p> */}
                        </div>
                    }
                    <div className='mb-4 action-btn-container' onClick={() => incrementShares(props?.data, props?.data?._id)}>
                        {/* <i className="fa-solid fa-message action-btn" >
                        </i> */}
                        <FontAwesomeIcon icon={faShare} />
                        {/* <p className={`title-reg ${props?.themeStyling ? "text-white" : "tex-dark"} text-center mb-0`}>Share </p> */}
                    </div>
                    <div className='mb-4 action-btn-container' onClick={(e) => onClickPlayButton(props?.data)}>
                        <FontAwesomeIcon icon={faPlay} />
                        {/* <p className={`title-reg ${props?.themeStyling ? "text-white" : "tex-dark"} text-center mb-0`}>Play</p> */}
                    </div>
                </div>
                <div className="mp-game-video-progress-bar d-md-none position-absolute">
                    <div className="mp-game-promo-video" style={{ width: `${progress}%` }}></div>
                </div>
            </div>}

            {showShareModal &&
                <div className={`modal fade text-dark ${showShareModal ? "show m-popup-show" : "false m-popup-hide"}`}
                    role="dialog"
                    aria-labelledby="exampleModalLabel"
                    aria-hidden="true" >
                    <div className="modal-dialog modal-dialog-centered">
                        <div className="modal-content moplay-corn-bg border">
                            <div className="border-bottom py-2 border-bottom-0 text-center mb-4 position-relative">
                                <h5 className="modal-title title-reg fs-4" id="exampleModalLabel">Share</h5>
                                <div onClick={() => setShowShareModal(false)} className='position-absolute moplay-lemon-bg top-0 end-0 p-2 h-100' style={{ cursor: 'pointer', borderTopRightRadius: 'var(--bs-border-radius-lg)' }}>
                                    <FontAwesomeIcon color='white' fontSize={25} icon={faClose}></FontAwesomeIcon>
                                </div>
                            </div>
                            <div className="modal-body text-white pt-0">
                                <div className='d-flex justify-content-center'>
                                    {/* <div className='text-center  py-lg-1 my-lg-2 mx-3'>
                                        <div className='rounded-circle twitter-icon px-2 py-0 ' style={{ height: "38px", width: "38px" }}>
                                            <svg xmlns="http://www.w3.org/2000/svg" width="60" height="39" fill="currentColor" className="bi bi-facebook text-primary" viewBox="0 0 16 16">
                                                <path d="M16 8.049c0-4.446-3.582-8.05-8-8.05C3.58 0-.002 3.603-.002 8.05c0 4.017 2.926 7.347 6.75 7.951v-5.625h-2.03V8.05H6.75V6.275c0-2.017 1.195-3.131 3.022-3.131.876 0 1.791.157 1.791.157v1.98h-1.009c-.993 0-1.303.621-1.303 1.258v1.51h2.218l-.354 2.326H9.25V16c3.824-.604 6.75-3.934 6.75-7.951" />
                                            </svg>
                                        </div>
                                        <div className='my-lg-2' style={{ marginTop: "20px" }}>Facebook</div>
                                    </div> */}
                                    <div className='text-center py-lg-1 my-lg-2 mx-3' style={{ cursor: 'pointer' }} onClick={onShareOnTwitter}>
                                        <div className='bg-dark rounded-circle twitter-icon px-2 py-1' style={{ height: "38px", width: "38px" }}>
                                            <svg xmlns="http://www.w3.org/2000/svg" width="20" height="33" fill="currentColor" className="bi bi-twitter-x text-white ms-1" viewBox="0 0 20 20">
                                                <path d="M12.6.75h2.454l-5.36 6.142L16 15.25h-4.937l-3.867-5.07-4.425 5.07H.316l5.733-6.57L0 .75h5.063l3.495 4.633L12.601.75Zm-.86 13.028h1.36L4.323 2.145H2.865z" />
                                            </svg>
                                        </div>
                                        <div className='my-lg-2' style={{ marginTop: "20px" }}>Twitter</div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            }
        </>
    )
}

const mapStateToProps = (state: any) => ({
    userloginDetails: state.UserStore.user
    //  Use 'counter: state.counter.counter' and replace the above line if you are using combineReducers to ensure that 'counter' matches the correct key in your store.
});

const mapDispatchToProps = (dispatch: any) => ({
    updateUserLoginStatus: () => dispatch({ data: PopupType.LOGIN, type: PlatformActionType.LOGIN }),
    updateShotIndex: (index: number) => dispatch({ data: index, type: PlatformActionType.INITIAL_VIDEO_SHOT })
})

export default connect(mapStateToProps, mapDispatchToProps)(VideoShot);