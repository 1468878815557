import React, { SyntheticEvent, useEffect, useRef, useState } from 'react'
import { SideMenu } from '../components/side-menu';
import { Link } from 'react-router-dom';
import { MediaUploadReq } from '../app/model/media-upload.req';
import { getMediaUploadUrl, uploadMediaToUrl } from '../app/service/media-upload.service';
import { ASSETS_URL, GAME_URL } from '../config/constant';
import FormBuilder, { FormBuilderData } from '../components/form-builder';
import { UserDetailById, UserUpdate } from '../app/service/login.service';
import { connect } from 'react-redux';
import HeaderBar from '../components/header-bar';
import { User } from '../app/model/dto/res/user';
import DisplayPicture from '../components/ui/display-picture';
import { Dispatch } from 'redux';
import { UserActionType } from '../app/redux/reducers/types';

interface Props {
    userloginDetails: User;
    updateUserProfile?: (data: User)=> void;
}

const UserInfo = (props: Props) => {
    const [loading, setLoading] = useState<boolean>(false);
    const [profileImage, setProfileImage] = useState('');
    const [profileImageFile, setProfileImageFile] = useState<any>();
    const [currentParentsData, setCurrentParentsData] = useState<any>({})
    const [parentsData, setParentsData] = useState<FormBuilderData>(
        {
            errors: [],
            isValid: false,
            value: {},
        }
    )
    const [isUserFormSubmitted, setIsUserFormSubmitted] = useState(false);

    useEffect(() => {
        const profile_img = props.userloginDetails?.profile_img;
        if(profile_img) {
            if(profile_img.startsWith('http')) {
                setProfileImage(profile_img)
            }else {
                setProfileImage(`${ASSETS_URL}${profile_img}`);
            }
        }
        setCurrentParentsData(props.userloginDetails);
        setParentsData({isValid: true, value: props.userloginDetails, errors: []});
    }, [props]);

    const handleImageChange = (e: any) => {
        const file = e.target.files[0];
        setProfileImageFile(file);
        if (file) {
            const reader: any = new FileReader();
            reader.onloadend = () => {
                setProfileImage(reader.result);
            };
            reader.readAsDataURL(file);
        }
    };

    const handleLoginInput = (data: any) => {
        data.value = { ...parentsData?.value, ...data.value };
        setParentsData(data);
    }

    const handleGenderChange = (e: any) => {
        const data = e.target.value;
        const updatedData = { ...parentsData.value, gender: data };
        setParentsData({ ...parentsData, value: updatedData });
    }

    const onProfileUpdate = async () => {
        setIsUserFormSubmitted(true);
        const updatedData = parentsData?.value;
        if (parentsData.isValid) {
            let profileFileRes: any;
            if (profileImageFile) {
                const profilePayload: MediaUploadReq = {
                    mediaUploadType: "profile-image",
                    fileName: profileImageFile?.name
                }
                profileFileRes = await getMediaUploadUrl(profilePayload);
                await uploadMediaToUrl(profileFileRes.url, profileImageFile, profileImageFile.type);
                setProfileImageFile(GAME_URL + profileFileRes?.path)
            }

            const userUpdatePayload: any = {
                _id: updatedData?._id ? updatedData?._id : currentParentsData?._id || "",
                name: updatedData?.name ? updatedData?.name : currentParentsData?.name || "",
                phone_number: updatedData?.phone_number ? updatedData?.phone_number : currentParentsData?.phone_number || "",
                address: updatedData?.address ? updatedData?.address : currentParentsData?.address || "",
                gender: updatedData?.gender ? updatedData?.gender : currentParentsData?.gender || "",
                profile_img: profileFileRes?.path as string || currentParentsData?.profile_img || ""
            };

            setLoading(true);
            try {
                const res = await UserUpdate(userUpdatePayload);
                // setCurrentParentsData(res);
                // loadUserDetails(currentParentsData?._id || "");
                setIsUserFormSubmitted(false);
                props.updateUserProfile(userUpdatePayload);
            } catch (error) {
                console.error("Error updating profile:", error);
            } finally {
                setLoading(false);
            }
        }
    };


    return (
        <>
            <div className={`container position-relative mb-5 pb-5`}>
                <div className='row pb-5'>
                    <div className='col-md-12 px-4'>
                        {/* <div className='my-2 fs-4 title-reg'>User Info</div> */}
                        <div className='position-relative mt-3'>
                            {loading &&
                                <>
                                    <div className="spinner-overlay">
                                        <div className="spinner-border text-white position-absolute mp-user-info-spinner-position" role="status">
                                            <span className="sr-only"></span>
                                        </div>
                                    </div>
                                </>
                            }
                            {!loading && (<>
                                <div className='row'>
                                    <div className='col-md-4 d-flex justify-content-center d-md-flex justify-content-start'>
                                        <div className='me-md-auto position-relative d-inline-block moplay-user-profile-image'>
                                            <DisplayPicture bg_class='bg-info' width={200} height={200} first_name={props.userloginDetails?.name || ''} last_name='' profile_pic={profileImage || props.userloginDetails?.profile_img}></DisplayPicture>
                                            <label htmlFor="profile-image-upload">
                                                <div className='bg-white position-absolute rounded-circle cursor-pointer mp-camera-icon-position'>
                                                    <i className="bi bi-camera text-dark position-relative fs-6 mp-camera-icon" title="upload your profile image"></i>
                                                </div>
                                            </label>
                                            <input id="profile-image-upload" type="file" accept="image/*" className='d-none' onChange={handleImageChange} />
                                        </div>
                                    </div>
                                </div>
                                <FormBuilder onUpdate={handleLoginInput} showValidations={isUserFormSubmitted}>
                                    <form action=''>
                                        <div className='row my-2'>
                                            <div className='col-md-6 d-flex flex-column justify-content-center'>
                                                <div className=''>
                                                    <label className='form-label'>Name</label>
                                                    <input type="text" name="name" className='form-control' defaultValue={currentParentsData?.name} placeholder='Please enter your name' data-validate-required="Please enter name" data-validate-name="name" />
                                                </div>
                                            </div>
                                            <div className='col-md-6'>
                                                <div className='my-2'>
                                                    <label className='form-label'>Mobile Number</label>
                                                    <input type="text" name="phone_number" maxLength={10} className='form-control' defaultValue={currentParentsData?.phone_number} placeholder='Please enter your mobile number' data-validate-required="Please enter phone number" data-validate-mobile-number="phone number" />
                                                </div>
                                            </div>
                                            <div className='col-md-6'>
                                                <div className='my-2'>
                                                    <label className='form-label'>Gender</label>
                                                    <select name="gender" className="form-select" aria-label="Default select example" value={parentsData?.value?.gender ? parentsData?.value?.gender : currentParentsData?.gender} onChange={(e) => handleGenderChange(e)}>
                                                        <option >Select Gender</option>
                                                        <option value="male">Male</option>
                                                        <option value="female">Female</option>
                                                        <option value="others">Others</option>
                                                    </select>
                                                </div>
                                            </div>
                                            <div className='col-md-6'>
                                                <div className='my-2'>
                                                    <label className='form-label'>Address</label>
                                                    <textarea name="address" rows={2} className='form-control' defaultValue={currentParentsData?.address} placeholder='Please enter your address' data-validate-required="Please enter address" ></textarea>
                                                </div>
                                            </div>
                                        </div>
                                    </form>
                                </FormBuilder>
                                <div className='d-flex justify-content-center my-4'>
                                    <Link to="/profile" className='w-100'><button className='btn btn-secondary w-100'>Cancel</button></Link>
                                    <button className='btn btn-primary ms-3 w-100' onClick={onProfileUpdate}>Update</button>
                                </div>
                            </>)}
                        </div>
                    </div>
                </div>
            </div >
        </>
    )
}

const mapStateToProps = (state: any) => ({
    userloginDetails: state.UserStore.user
});

const mapDispatchToProps = (dispatch: Dispatch)=> ({
    updateUserProfile: (data: User)=>{dispatch({type: UserActionType.UPDATE_USER_PROFILE, data})}
}) 
export default connect(mapStateToProps, mapDispatchToProps)(UserInfo);
// export { connected as UserInfo };