import React, { SyntheticEvent, useEffect, useRef, useState } from 'react'
import { StatusGames } from '../app/service/statusgame.service';
import { SideMenu } from '../components/side-menu';
import { GamesList } from '../components/games-list';

export const SavedGames = () => {
    const [previousPosition, setPreviousPosition] = useState(0);
    const [activeElement, setActiveElement] = useState<string | number>(1);
    const [nextElement, setNextElement] = useState<string | number>(1);
    const [userFlowActionView, setUseerFlowActionview] = useState<boolean>(false);
    const [themeStatus, setThemeStatus] = useState<any>(true);
    const [searchValue, setSearchValue] = useState('');
    const [showLoginPopup, setShowLoginPopup] = useState<boolean>(false);
    const [userLoginData, setUserLoginData] = useState('');
    const [gameData, setGameData] = useState([]);
    const loginPopupRef: any = useRef();

    useEffect(() => {
        gameShow()
    }, []);

    useEffect(() => {
        if (loginPopupRef) {
            setUseerFlowActionview(false)
        }
    }, [loginPopupRef])

    function gameShow() {
        const userData: any = localStorage.getItem("userData");
        const parsedUserData = JSON.parse(userData)
        StatusGames(parsedUserData?._id).then((res: any) => {
            setGameData(res)
        })
    }

    const handleScroll = (event: SyntheticEvent) => {
        const target = event.target as HTMLElement;
        const scrollDiff = target.scrollHeight - target.scrollTop;
        if (target.scrollTop - previousPosition > 250) {
            setPreviousPosition(target.scrollTop);

        } else if (target.scrollTop - previousPosition < -250) {
            setPreviousPosition(target.scrollTop);
        }
        if (activeElement !== nextElement) {
            setActiveElement(nextElement);
            event.stopPropagation();
            event.preventDefault();
            const elementDom = document.getElementById(`video_element_${nextElement}`);
            if (elementDom) {
                elementDom.scrollIntoView({ behavior: 'smooth', block: 'end' });
                window.scrollTo(0, 0);
            }
        }
    }

    function getThemeStatus(status: boolean) {
        setThemeStatus(status);
    }

    function findData(data: any) {
        setSearchValue(data);
    }

    function userLogInData(data: any) {
        setUserLoginData(data)
    }

    const getLoginStatus = (status: any) => {
        setShowLoginPopup(false)
        setUseerFlowActionview(false);
    }

    return (
        <>
            <div className={`${themeStatus ? 'bg-dark text-white' : "bg-light text-dark"} container-fluid pe-3 scroll-container position-relative`} ref={loginPopupRef} onScroll={handleScroll}>
                <div className='row'>
                    <div className='col-md-3 px-0'>
                        <SideMenu activeMenu={"saved-games"} />
                    </div>
                    <div className='col-md-9'>
                        <div className='my-2 fs-4'>All Games</div>
                        <GamesList items={gameData} />
                    </div>
                </div>
            </div >
        </>
    )
}
