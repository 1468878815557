import React, { SyntheticEvent, useEffect, useRef, useState } from 'react'
import { SideMenu } from '../components/side-menu';
import { GamesList } from '../components/games-list';
import { LikedGames } from '../app/service/game.service';
import { MobileViewMenu } from '../components/mobile-view-menu';
import Header from '../components/header';
import HeaderBar from '../components/header-bar';
import { BackBtnTitle } from '../app/components/back-btn-title';
import { Loading } from '../app/components/loading';

export const Likes = () => {
    const [userFlowActionView, setUseerFlowActionview] = useState<boolean>(false);
    const [gameData, setGameData] = useState([]);
    const [canShowLoading, setCanShowLoading] = useState(true);

    useEffect(() => {
        loadLikedGames()
    }, []);

    function loadLikedGames() {
        const userData: any = localStorage.getItem("userData");
        const parsedUserData = JSON.parse(userData)
        setCanShowLoading(true);
        LikedGames(parsedUserData?._id || "").then((res: any) => {
            setGameData(res)
            setCanShowLoading(false);
        })
    }

    return (
        <div className={`container px-3  position-relative`} >
            <BackBtnTitle text='Likes' path='/'></BackBtnTitle>
            {canShowLoading && <Loading></Loading>}
            <div className='row'>
                <div className='col-md-12 px-4'>
                    <GamesList items={gameData} likedGameNavigation={true} />
                </div>
            </div>
        </div >
    )
}
