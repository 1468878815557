import React, { useEffect, useState } from 'react'
import { Link, useLocation, useNavigate } from 'react-router-dom'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faClock, faHeart, faHouse, faShop, faUser, faUserPlus } from '@fortawesome/free-solid-svg-icons';
import { connect } from 'react-redux';
import { MobileViewMenu } from './mobile-view-menu';

interface Props {
    userloginDetails?: any;
    activeMenu?: String;
    userData?: any;
    showLoginPopup?: any;
    popUpuserFlowAction?: any;
    loginStatus?: any;
    userdata?: any;
    userLoginStatus?: any;
    updateUserLoginStatus?: (data: any) => void;
}

const SideMenu = (props: Props) => {
    const [userPopUpFlowAction, setuserPopUpFlowAction] = useState<boolean>();
    const [userloginData, setUserLoginData] = useState<any>({});
    const [activeMenu, setActiveMenu] = useState<any>('');
    const [userDetails, setUserDetails] = useState<any>(null);
    const [loginDetails, setLoginDetails] = useState<any>({});
    const [userIdView, setUserIdView] = useState(false);
    const [isLogin, setISLogin] = useState(false);
    const navigation = useNavigate();
    const location = useLocation();

    useEffect(() => {
        const path = location.pathname;
        setActiveMenu(getActiveMenu(path));
    }, [location]);

    const getActiveMenu = (path: string) => {
        switch (path) {
            case '/home':
                return 'home';
            case '/social':
                return 'social';
            case '/profile':
                return 'profile';
            case '/saved-games':
                return 'saved-games';
            case '/likes':
                return 'likes';
            case '/shop':
                return 'shop';
            case '/recently-played':
                return 'recently-played';
            default:
                return 'home';
        }
    };

    useEffect(() => {
        const userData: any = localStorage.getItem("userData");
        if (userData) {
            const loginUserData: any = JSON.parse(userData);
            setUserDetails(loginUserData);
            setLoginDetails(loginUserData)
            setUserIdView(true)
            setISLogin(true);
        }
    }, []);

    useEffect(() => {
        setuserPopUpFlowAction(props.popUpuserFlowAction);
    }, [userPopUpFlowAction,])

    useEffect(() => {
        if (props.userdata) {
            setISLogin(true);
            setUserLoginData(props.userdata)
        }
    }, [props.userdata])

    const onClickMenuNavigation = (data: any) => {
        if (data === "Profile") {
            if (props.userdata == null || props.userdata == "undefined") {
                if (props.updateUserLoginStatus) {
                    props.updateUserLoginStatus(true);
                }
            } else {

                navigation("/profile");
            }
        } else if (data === "Social") {
            if (props.userdata == null || props.userdata == "undefined") {
                if (props.updateUserLoginStatus) {
                    props.updateUserLoginStatus(true);
                }
            }
            else {
                navigation("/social");
            }
        } else if (data === "Saved Games") {
            if (userloginData?.name == null || userloginData?.name === "undefined") {
                if (props.updateUserLoginStatus) {
                    props.updateUserLoginStatus(true);
                }
            }
            else {
                navigation("/saved-games");
            }
        } else if (data === "Likes") {
            if (userloginData.name == null || userloginData?.name === "undefined") {
                if (props.updateUserLoginStatus) {
                    props.updateUserLoginStatus(true);
                }
            } else {
                navigation("/likes");
            }
        } else if (data === "Shop") {
            if (userloginData?.name == null || userloginData?.name === "undefined") {
                if (props.updateUserLoginStatus) {
                    props.updateUserLoginStatus(true);
                }
            } else {
                navigation("/shop");
            }
        } else if (data === "Recently Played") {
            if (isLogin) {
                navigation("/recently-played");
            }
            else if (userloginData?.name == null || userloginData?.name === "undefined") {
                if (props.updateUserLoginStatus) {
                    props.updateUserLoginStatus(true);
                }
            }
        } else {
            navigation(data);
        }
    }

    return (
        <div>
            <div className='position-fixed d-none d-md-block'>
                <ul className='mt-3 ps-2 nav-items-list title-reg' style={{ listStyle: 'none', cursor: "pointer" }}>
                    <Link to="/home" className='text-decoration-none text-dark'>
                        <li className={`nav-ink ${activeMenu === 'home' ? 'active' : ''}`} style={{ padding: '10px', color: activeMenu === 'home' ? "#ff9e00" : "#ffffff" }} >
                            <i className={`me-3 me-md-2 pe-md-1`}><FontAwesomeIcon icon={faHouse} /></i>
                            Home</li>
                    </Link>
                    <li className={`nav-ink cursor-pointer ${activeMenu === 'social' ? 'active' : ''}`} onClick={() => onClickMenuNavigation("Social")} style={{ padding: '10px', color: activeMenu === 'social' ? "#ff9e00" : "#ffffff" }}>
                        <i className={`me-3 me-md-2 pe-md-0`}><FontAwesomeIcon icon={faUserPlus} /></i>
                        Social</li>
                    {/* <Link to="/user-info" className='text-decoration-none text-dark'> */}
                    <li className={`nav-ink cursor-pointer ${activeMenu === 'profile' ? 'active' : ''}`} onClick={() => onClickMenuNavigation("Profile")} style={{ padding: '10px', color: activeMenu === 'profile' ? "#ff9e00" : "#ffffff" }}>
                        <i className={`me-3`}><FontAwesomeIcon icon={faUser} style={{ color: "#08ddba" }} /></i>
                        Profile</li>
                    {/* </Link> */}

                    {/* <Link to="/saved-games" className='text-decoration-none text-dark'> */}
                    {/* <li className={` cursor-pointer ${activeMenu === 'saved-games' ? 'active' : ''}`} style={{ padding: '10px' }} onClick={() => onClickMenuNavigation("Saved Games")}>
                    <i style={{ color: '#fdf12b' }} className={`me-3`}><FontAwesomeIcon icon={faBookmark} /></i>
                    Saved Games
                </li> */}

                    {/* </Link> */}
                    {/* <Link to="/likes" className='text-decoration-none text-dark'> */}
                    {/* <li className={`cursor-pointer ${activeMenu === 'likes' ? 'active' : ''}`} style={{ padding: '10px', color: activeMenu === 'likes' ? "#ff9e00" : "#ffffff" }} onClick={() => onClickMenuNavigation("Likes")}>
                        <i style={{ color: '#fd452b' }} className={`me-3`}><FontAwesomeIcon icon={faHeart} /></i>
                        Likes
                    </li> */}
                    <li className={`cursor-pointer ${activeMenu === 'shop' ? 'active' : ''}`} style={{ padding: '10px', color: activeMenu === 'shop' ? "#ff9e00" : "#ffffff" }} onClick={() => onClickMenuNavigation("Shop")}>
                        <i style={{ color: '#fbad04' }} className={`me-2 pe-1`}><FontAwesomeIcon icon={faShop} /></i>
                        Shop
                    </li>
                    <li className={`cursor-pointer ${activeMenu === 'shop' ? 'active' : ''}`} style={{ padding: '10px', color: activeMenu === 'shop' ? "#ff9e00" : "#ffffff" }} onClick={() => onClickMenuNavigation("blog")}>
                        <i style={{ color: '#fbad04' }} className={`me-2 pe-1`}><FontAwesomeIcon icon={faShop} /></i>
                        Blog
                    </li>
                    {/* </Link> */}
                    {/* <Link to="/recently-played" className='text-decoration-none text-dark'> */}
                    {/* <li className={`cursor-pointer ${activeMenu === 'recently-played' ? 'active' : ''}`} style={{ padding: '10px', color: activeMenu === 'recently-played' ? "#ff9e00" : "#ffffff" }} onClick={() => onClickMenuNavigation("Recently Played")}>
                        <i style={{ color: '#2bc2fd' }} className={`me-3`}><FontAwesomeIcon icon={faClock} /></i>
                        Recently Played
                    </li> */}
                    {/* </Link> */}
                </ul>
            </div>
            <div className='d-block d-md-none'>
                <MobileViewMenu />
            </div>
        </div>
    )
}

const mapStateToProps = (state: any) => ({
    userdata: state.UserStore.user,
    userLoginStatus: state.UserStore.loginstatus
});

const mapDispatchToProps = (dispatch: any) => ({
    updateUserLoginStatus: (data: any) => dispatch({ loginstatus: data, type: 'loginstatus' })
})

const connectSidemenu = connect(mapStateToProps, mapDispatchToProps)(SideMenu);
export { connectSidemenu as SideMenu };