import React, { SyntheticEvent, useEffect, useRef, useState } from 'react'
// import { SideMenu } from '../components/side-menu'
import { NotificationRes } from '../app/model/notifcation.res';
import { NotificationService } from '../app/service/notification.service';
import { connect } from 'react-redux';
import { GameService } from '../app/service/game.service';
import { useNavigate } from 'react-router-dom';
import { PopupType } from '../app/redux/reducers/types';
import HeaderBar from '../components/header-bar';
import { GAME_URL } from '../config/constant';
import { CircleDot } from '../app/components/circle-dot';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faClose } from '@fortawesome/free-solid-svg-icons';

interface Props {
    userdata: any;
    notificationPopup?: (data: any) => void;
    showLoginPopup?: () => void;
    onClose: () => void;
    canShow: boolean;
}

const Notifications = (props: Props) => {
    const [notificationData, setNotificationData] = useState<NotificationRes[]>([]);
    const navigation = useNavigate();

    useEffect(() => {
        if (!props?.userdata?.user) {
            if (props.showLoginPopup) {
                props.showLoginPopup();
            }
        }
    }, []);
    useEffect(() => {
        if (props?.userdata && props?.userdata?.user?._id) {
            loadNotifications(props?.userdata?.user?._id);
        } else {

        }
    }, [props]);

    const loadNotifications = async (user: string) => {
        const notifications = await NotificationService.getNotifications(user);
        notifications.forEach(element => {
            if (!element.icon?.startsWith('http')) {
                element.icon = `${GAME_URL}${element.icon}`;
            }
        });
        setNotificationData(notifications);
    }

    const onOpenNotification = async (notification: NotificationRes) => {
        await NotificationService.open(notification._id);
        switch (notification.type) {
            case 'challenge':
                const challegeData = await GameService.getChallenge(notification.notification_data);
                if (props?.notificationPopup) {
                    props?.notificationPopup(true);
                }
                navigation(`/detail/${challegeData?.game}?challengeId=${challegeData?._id}`);
                props.onClose();
                break;
            default:
                break;
        }
    }

    return (
        <>
            <div className={`mp-scroll-bar-width px-3 notifications ${props.canShow? 'open':'close'}`}>
                <div className='row '>
                    <div className='col-md-12 px-4'>
                        <div className='d-flex justify-content-between text-white align-items-center'>
                            <b className='p-3' >Notifications</b>
                            <FontAwesomeIcon onClick={props.onClose} className='pointer' icon={faClose}></FontAwesomeIcon>
                        </div>
                        {notificationData.length < 1 && <div className='my-5 text-center'>You do not have any notifications</div>}
                        <div className=''>
                            {notificationData?.map((data, index: number) => (
                                <div className='d-flex align-items-center justify-content-center  pointer p-3 border rounded mb-2 bg-white' key={index} onClick={() => onOpenNotification(data)}>
                                    {data.icon && <img src={data.icon} className="mp-notification-profile-img" />}
                                    <div className='mx-3 mx-md-4'>
                                        <div>{data.content}</div>
                                    </div>
                                    {data.status === 0 && <div>
                                        <CircleDot size={10} color='#00ff00'></CircleDot>
                                    </div>}
                                </div>
                            ))}
                        </div>
                    </div>
                </div>
            </div >
        </>
    )
}

const mapStateToProps = (state: any) => ({
    userdata: state.UserStore
});
const mapDispatchToProps = (dispatch: any) => ({
    showLoginPopup: () => dispatch({ data: PopupType.LOGIN, type: 'popup' }),
})
export default connect(mapStateToProps, mapDispatchToProps)(Notifications);