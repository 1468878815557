import React, { useState } from 'react'
import { BackBtnTitle } from '../components/back-btn-title'
import { useNavigate } from 'react-router-dom';
import { MrPopup } from '../components/mr-popup';

export const Contact = () => {
    const [canShowPopup, setCanShowPopup] = useState(false);
    const navigator = useNavigate();

    const onSubmit = () => {
        // navigator('/');
        setCanShowPopup(true);
    }

    const onClose = () => {
        navigator('/');
    }
    return (
        <div className='container pb-5'>
            <BackBtnTitle text='Contact' path='/'></BackBtnTitle>
            <div className="row">
                <div className="col-lg-6">
                    <div className="form-group mb-3">
                        <label htmlFor="contact_name">Name</label>
                        <input className='form-control' type="text" name="" id="contact_name" />
                    </div>

                    <div className="form-group mb-3">
                        <label htmlFor="contact_email">Email</label>
                        <input className='form-control' type="text" name="" id="contact_email" />
                    </div>

                    <div className="form-group mb-3">
                        <label htmlFor="contact_msg">Message</label>
                        <textarea className='form-control bg-white' rows={6} name="" id="contact_msg" />
                    </div>

                    <div className="text-center">
                        <button className="btn btn-primary" onClick={onSubmit}>Submit</button>
                    </div>
                </div>
            </div>
            {
                canShowPopup && <MrPopup content='Message submitted successfully.' heading='Success'>
                    <button className="btn btn-primary" onClick={onClose}>Ok</button>
                </MrPopup>
            }
        </div>
    )
}
