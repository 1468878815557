import { LeaderboardRes } from "../model/dto/res/leaderboard.res";
import { MultiplayerBot } from "../model/dto/res/multiplayer-bot";
import { GameLikeReq } from "../model/game-like.req";
import API from "../utility/axios";

export class GameService {
    static async getChallenge(id: string): Promise<any> {
        return API.get(`/challenge/${id}`);
    }

    static async joinMultiplayer(userId: string, gameId: string): Promise<string> {
        return API.post('/multiplayer/join', { userId, gameId });
    }

    static async getLoaderboard(game: string): Promise<LeaderboardRes[]> {
        return API.get(`/scores/${game}`);
    }

    static async getMultiplayerBotOpponent(game: string): Promise<MultiplayerBot> {
        return API.get(`/player-move/${game}`);
    }

    static async updateGameLike(data: any): Promise<MultiplayerBot> {
        return API.post(`/game/like`, data);
    }

    static async rate(game: string, value: number): Promise<MultiplayerBot> {
        return API.post(`/game/rate`, { game, value });
    }
}

export const Game = (): Promise<any> => {
    return API.get(`/game`);
}

export const GameById = (GameId: any): Promise<any> => {
    return API.get(`/game/${GameId}`);
}

export const GameUpdate = (): Promise<any> => {
    return API.put(`/game`);
}

// export const GameLike = (data: any): Promise<any> => {
//     return API.post(`/game/like`, data);
// }

export const LikedGames = (userId: any): Promise<any> => {
    return API.get(`/game/liked?user=${userId}`);
}

export const GamePublisherDetails = (userId: any): Promise<any> => {
    return API.get(`/game/publisher/${userId}`);
}

export const PublisherDetailsById = (userId: any): Promise<any> => {
    return API.get(`/publisher/${userId}`);
}

export const GameChallenge = (data: any): Promise<any> => {
    return API.post(`/challenge`, data);
}