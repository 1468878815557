import React, { useEffect, useState } from 'react'
import HeaderBar from '../../components/header-bar'
import { connect } from 'react-redux';
import DisplayPicture from '../../components/ui/display-picture';
import { User } from '../model/dto/res/user';
import { GameService } from '../service/game.service';
import { useNavigate, useParams } from 'react-router-dom';
import { SocketSvc } from '../service/socket.service';
import { MULITPLAYER_STATE, PlatformActionType } from '../redux/reducers/types';
import { MultiplayerParticipant } from '../model/dto/socket/multiplayer-participant';
import { JoinRoom } from '../model/dto/socket/join-room';
import { CountTimer } from '../components/count-timer';
import { Resources } from '../utility/resources';
import { MultiplayerBot } from '../model/dto/res/multiplayer-bot';

interface Props {
    user?: User;
    participants?: MultiplayerParticipant[];
    initliazeWaitingRoom?: (data: MultiplayerParticipant) => void;
    multiplayerState: MULITPLAYER_STATE;
    clearMultiplayer: () => void;
    setMultiplayerBot?: (data: MultiplayerBot) => void;
}
export const WaitingRoom = (props: Props) => {
    const [roomId, setRoomId] = useState('');
    const [opponentUser, setOpponentUser] = useState<MultiplayerParticipant>();
    const [canRetry, setCanRetry] = useState(false);
    const [isRetryied, setIsRetryied] = useState(false);
    let isRoomJoining = false;
    let { id } = useParams<{ id: string }>();
    const navigation = useNavigate();

    useEffect(() => {
        if (!isRoomJoining) {
            joinRoom();
        }
        isRoomJoining = true;
    }, []);

    useEffect(() => {
        if (props.multiplayerState === MULITPLAYER_STATE.CHECK_READYNESS) {
            // We can implement any conditions to start the game. As of now no conditions
            navigation(`/play/${id}`);
        }
    }, [props.multiplayerState])

    useEffect(() => {
        if (props.participants && props.participants.length >= 2 && roomId && props.user) {
            const data: JoinRoom = {
                profile_pic: props.user?.profile_img,
                room_id: roomId,
                user_id: props.user?._id,
                user_name: props.user?.name
            }
            SocketSvc.sendGameplayRequest(data);
            setOpponentUser(props.participants[1]);
        }
    }, [props.participants]);

    const joinRoom = async () => {
        isRoomJoining = true;
        if (props.user && id) {
            // get room id from server
            const rId = await GameService.joinMultiplayer(props.user?._id, id);
            setRoomId(rId);
            // emit join msg to socket server
            SocketSvc.joinMultiplayer({
                profile_pic: props.user.profile_img,
                room_id: rId,
                user_id: props.user._id,
                user_name: props.user.name
            });
            // update participants list in redux store 
            if (props.initliazeWaitingRoom) {
                props.initliazeWaitingRoom({
                    id: props.user._id,
                    name: props.user.name,
                    profile_pic: props.user.profile_img,
                    room_id: rId
                });
            }
        }
    }

    const onOpponentNotFound = () => {
        isRoomJoining = false;
        // if (isRetryied) {
        //     tryWithBot();
        // }
        tryWithBot();
        setCanRetry(true);
        if (roomId) {
            SocketSvc.exitGameRoom({
                gameId: id,
                roomId,
                score: 0,
                userId: props.user._id
            }, roomId);
        }
    }

    const onRetry = () => {
        if (props.clearMultiplayer) {
            props.clearMultiplayer();
        }

        joinRoom();
        setCanRetry(false);
        setIsRetryied(true);
    }

    const tryWithBot = async () => {
        try {
            const botData = await GameService.getMultiplayerBotOpponent(id);
            if (botData && botData.moves && botData.moves.length) {
                if (props.setMultiplayerBot) {
                    props.setMultiplayerBot(botData);
                    navigation(`/play/${id}`);
                }
            }
        } catch (error) {

        }
    }

    const goToGame = () => {
        navigation(`/detail/${id}`);

    }

    return (
        <div className="py-5">
            <h4 className='text-center  border-bottom pb-2'>Waiting for opponents</h4>
            <div className="d-flex px-3 justify-content-center align-items-center mt-4">
                <div className='d-flex flex-column align-items-center'>
                    <DisplayPicture bg_class='bg-info' first_name={props.user?.name || ''} last_name='' profile_pic={props.user?.profile_img}></DisplayPicture>
                    <h6 className='mt-3 text-center'>{props.user?.name}</h6>
                </div>
                <h2 className='mx-4'>V/s</h2>
                {/* <img src={Resources.VS_IMG_JPG} alt="" /> */}
                {opponentUser && <div>
                    <DisplayPicture first_name={opponentUser.name || ''} last_name='' profile_pic={opponentUser.profile_pic}></DisplayPicture>
                </div>}
                {
                    !opponentUser && <div className='d-flex flex-column align-items-center'>
                        {
                            !canRetry &&
                            <>
                                <CountTimer maxTime={10} size={100} onCounterEnd={onOpponentNotFound}></CountTimer>
                                <h6 className='mt-3 text-center'>Searching...</h6>
                            </>
                        }
                        {/* {!canRetry && <h6 className='mt-3 text-center'>Searching...</h6>} */}
                        {/* {
                                canRetry &&
                                <div>
                                    <DisplayPicture first_name='?' last_name='' profile_pic=''></DisplayPicture>
                                    <h6 className='mt-3 text-center'>Not found</h6>
                                </div>
                            } */}
                    </div>
                }
            </div>
            {
                canRetry &&
                <div className='mt-5 text-center'>
                    <p className=''>Opponents not available</p>
                    <button className="btn btn-primary" style={{ width: 200 }} onClick={onRetry}>Retry</button>
                </div>
            }
            <div className='mt-3 text-center'>
                <button className="btn btn-outline-primary" style={{ width: 200 }} onClick={goToGame}>Quit</button>
            </div>
        </div>
    )
}

const mapStateToProps = (state: any) => ({
    user: state.UserStore.user,
    participants: state.PlatformStore.multiplayerParticipants,
    multiplayerState: state.PlatformStore.multiplayerState
});

const mapDispatchToProps = (dispatch: any) => ({
    initliazeWaitingRoom: (data: MultiplayerParticipant) => dispatch({ data, type: PlatformActionType.MULTIPLAYER_WAITING }),
    clearMultiplayer: () => dispatch({ type: PlatformActionType.CLEAR_MULTIPLAYER }),
    setMultiplayerBot: (data: MultiplayerBot) => dispatch({ type: PlatformActionType.MULTIPLAYER_BOT, data })
})

export default connect(mapStateToProps, mapDispatchToProps)(WaitingRoom);
