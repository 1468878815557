import React, { useEffect, useState } from 'react'
import { ASSETS_URL } from '../../config/constant';

interface Props {
  first_name: string;
  last_name: string;
  profile_pic?: string;
  width?: number;
  height?: number;
  text_size?: number;
  bg_class?: string;
  fitting?: 'contain' | 'cover';
}

const DisplayPicture = (props: Props) => {
  const [profilePic, setProfilePic] = useState('');
  const [styleObj, setStyleObj] = useState({});

  useEffect(() => {
    if (props.profile_pic) {
      if (props.profile_pic.startsWith('data:image')) {
        setProfilePic(props.profile_pic);
      } else if (typeof props.profile_pic === 'string') {

        if (props.profile_pic.startsWith('http')) {
          setProfilePic(props.profile_pic);
        } else {
          setProfilePic(`${ASSETS_URL}${props.profile_pic}`)
        }
      } else {
        setProfilePic(props.profile_pic);
      }
    }
    setStyleObj({ width: props.width || 100, height: props.height || 100, borderRadius: '50%' });
  }, [props]);

  return (
    <div>
      {profilePic && <img src={profilePic} style={{ ...styleObj, objectFit: props.fitting || 'contain' }}></img>}
      {
        !profilePic &&
        <div className={`${props.bg_class || 'bg-white'} d-flex justify-content-center align-items-center`} style={styleObj}>
          <h2 style={{fontSize: props.text_size}} className={`text-dark ${props.text_size ? '' : 'f-45'}`}>{props.first_name[0]}</h2>
        </div>
      }
    </div>
  )
}

export default DisplayPicture