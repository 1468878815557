import { faSpinner } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import React from 'react'

export const Loading = () => {
    return (
        <div className="loading">
            <FontAwesomeIcon icon={faSpinner} className='fa-spin f-25'></FontAwesomeIcon>
            <span>Please wait</span>
        </div>
    )
}
