import React, { useState } from 'react'
import { MrPopup } from './mr-popup'
import { Game } from '../model/dto/res/game';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faStar } from '@fortawesome/free-solid-svg-icons';
import { GameService } from '../service/game.service';

interface Props {
    game: Game;
    onClose: ()=> void;
}

export const RateApp = (props: Props) => {
    const ratings = [1, 2, 3, 4, 5];
    const [selectedRating, setSelectedRating] = useState(0);
    const onRate = (rating: number) => {
        setSelectedRating(rating);
    }

    const onSubmit = async () => {
        await GameService.rate(props.game._id, selectedRating);
        props.onClose();
    }

    return (
        <div>
            <MrPopup heading='Rate This Game' content='Thanks for the support!' onClose={props.onClose}>
                <div className="d-flex mb-3 justify-content-center">
                    {ratings.map(el => <FontAwesomeIcon key={el} color={el <= selectedRating ? '#ff9e00' : ''} className='me-3' style={{ fontSize: 30 }} icon={faStar} onClick={() => onRate(el)}></FontAwesomeIcon>)}
                </div>
                <div className="text-center mt-5">
                    <button className="btn btn-primary" onClick={onSubmit}>Submit</button>
                </div>
            </MrPopup>
        </div>
    )
}
