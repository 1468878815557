import API from "../utility/axios";
import { MoplayCache } from "./moplay-cache";

export const StatusGames = (userID: string): Promise<any> => {
    if (MoplayCache.games.allGames) {
        return new Promise((resolve) => resolve(MoplayCache.games.allGames));
    } else {
        return API.get(`game/published?user=${userID}`);
    }
    // return API.get(`/publishedgames`);
}