import { ChallengeSubmitReq } from "../model/dto/req/challenge-submit.req";
import { ChallengeInfo } from "../model/dto/res/challenge-info";
import API from "../utility/axios";

export class ChallengeService {
    static async getChallenge(id: string): Promise<ChallengeInfo> {
        return API.get(`/challenge/${id}`);
    }
    static async getChallengeInfo(id: string): Promise<ChallengeInfo[]> {
        return API.get(`/challenge/info/${id}`);
    }

    static async acceptChallenge(id: string): Promise<ChallengeInfo[]> {
        return API.post(`/challenge/accept/${id}`);
    }

    static async submitChallenge(data: ChallengeSubmitReq): Promise<ChallengeInfo[]> {
        return API.post(`/challenge/submit`, data);
    }

    static async getAllChallenges(): Promise<ChallengeInfo[]> {
        return API.get(`/challenge/all`);
    }
}