import { GoogleLogin } from '@react-oauth/google';
import { jwtDecode } from 'jwt-decode';
import React, { MouseEvent, useState } from 'react'
import { UserService } from '../app/service/user.service';
import { Link, useNavigate } from 'react-router-dom';
import FormBuilder from './form-builder';
import { SIGNIN } from '../app/service/login.service';
import { connect } from 'react-redux';
import { PopupType } from '../app/redux/reducers/types';
import { SocketSvc } from '../app/service/socket.service';
import CloseIcon from './../assets/images/icons/close-icon.png';
import { Resources } from '../app/utility/resources';

interface Props {
    updateUserLogin?: (data: any) => void;
    hideLoginPopup?: () => void;
}

const Login: React.FC<Props> = (props: Props) => {
    const [parentsData, setParentsData] = useState<any>({});
    const [isUserFormSubmitted, setIsUserFormSubmitted] = useState(false);
    const [loading, setLoading] = useState<boolean>(false);
    const [validationError, setValidationError] = useState('');
    const stopPropagation = (event: MouseEvent) => { event.stopPropagation(); }
    const closeLoginPopup = (event: MouseEvent) => {
    }
    const navigation = useNavigate();

    const handleGoogleLogin = async (response: any) => {
        if (response) {
            const decoded = jwtDecode(response.credential as string);
            console.log(decoded, response);
            const loginRes = await UserService.loginWithGoogle({ token: response.credential });
            onLoginSuccess(loginRes);
        }
    };

    const handleLoginInput = (data: any) => {
        setParentsData(data);
    };

    const userFormSubmit = () => {
        setIsUserFormSubmitted(true);
        const data = { ...parentsData.value };
        if (parentsData.isValid) {
            setLoading(true)
            SIGNIN(data).then((res: any) => {
                if (res._id) {
                    onLoginSuccess(res);
                }

                setLoading(false);
            })
                .catch((err) => {
                    console.error(err);
                    setValidationError('Enter Valid Email Id or Password');
                    setLoading(false);
                })
        }
    }
    const onLoginSuccess = async (res: any) => {
        SocketSvc.connect(res._id);
        // localStorage.setItem('userData', JSON.stringify(res));
        setLoading(false);
        await UserService.updateUserSession(res._id, SocketSvc.SocketID, res.token);
        if (props.updateUserLogin) {
            props.updateUserLogin(res);
        }
    }

    const gotoHome = () => {
        if (props.hideLoginPopup) {
            props.hideLoginPopup();
        }
        navigation('/home');
    }

    return (
        <div className="m-popup full-cover mb-3" onClick={closeLoginPopup}>
            <div className="m-popup-body mb-5 pb-5 justify-content-center align-items-center sx-bg-page position-relative " onClick={stopPropagation}>
                <div className='m-popup-header-bg'>
                    <img src={Resources.POPUP_HEADER_IMAGE} className='w-100' />
                    <h4 className='mp-popup-header-position mp-text-clr fw-bold'>Log In</h4>
                </div>
                <div className="shadow text-white border border-3 mp-popup-border-color moplay-corn-bg p-5 px-md-5 flex-column shadow p-3 mb-5 rounded-5 position-relative">
                    {/* <h4 className='text-center mb-4 title-reg fw-bold f-20 text-dark'>Log In</h4> */}
                    {/* <div className="popup-heading border-0 d-flex justify-content-center text-center mp-text-clr mx-auto">
                        <h4 className='fw-bold'>Log In</h4>
                    </div> */}
                    <div className="row vh-100-all">
                        <div className="col-12 col-lg-8 px-2 mb-2 w-100 justify-content-center">
                            <FormBuilder onUpdate={handleLoginInput} showValidations={isUserFormSubmitted}>
                                <form action="">
                                    <div className="form-group mb-3 position-relative" style={{ zIndex: "12223"}}>
                                        <label className="mp-text-clr" htmlFor="login_username">Email</label>
                                        <input className="form-control " type="text" name="email" placeholder="Email" data-validate-required="Please enter your email" data-validate-email="email Id" />
                                    </div>
                                    <div className="form-group mb-3">
                                        <label className="mp-text-clr" htmlFor="login_username">Password</label>
                                        <input type="password" name="password" id="login_password" className="form-control" placeholder="Password" data-validate-required="Please enter password" data-validate-password-user="password" />
                                    </div>
                                </form>
                            </FormBuilder>
                            {/* <div className='mb-3'>
                                <span className='text-primary text-start text-decoration-underline pointer'>Forgot Password</span>
                            </div> */}
                            <div className='mx-auto d-flex justify-content-center'>
                                <button type="submit" className='btn btn-primary border rounded-4 w-75 px-0' onClick={userFormSubmit} style={{ fontFamily: "Montserrat", fontWeight: "bold" }} >
                                    {loading &&
                                        <div className="text-center spinner">
                                            <div className="spinner-border spinner-border-sm login-spinner-border" role="status">
                                                <span className="sr-only"></span>
                                            </div>
                                        </div>
                                    }
                                    {!loading &&
                                        <span className='text-dark'>Log in</span>
                                    }
                                </button>
                            </div>
                            {validationError && <p className="text-danger gameUser_form_label">{validationError}</p>}
                        </div>
                    </div>
                    <div className="text-center" style={{ display: "flex" }}><span className='my-2 me-2' style={{ width: "50%" }}><hr /></span><span className='my-2 py-1 mp-text-clr'>OR</span><span className='my-2 ms-2' style={{ width: "50%" }}><hr /></span></div>
                    <div className='rounded-3 mx-auto d-flex justify-content-center'>
                        {/* <button className='btn btn-primary px-3' onClick={handleGoogleLogin}><span className='me-2'><img src={GoogleIconImage} style={{ height: "30px", width: "30px", objectFit: "contain" }} /></span>Continute with Google</button> */}
                        <GoogleLogin
                            onSuccess={handleGoogleLogin}
                            onError={() => {
                                console.log('Login Failed');
                            }}
                        />
                    </div>
                    <div className='my-3 text-center mx-auto'>
                        <span className='mp-text-clr'>If you don't have an account?</span>
                    </div>
                    <div className='mx-auto text-center'><Link className='mp-text-clr fw-bold text-decoration-underline f-20' to="/registration" style={{ fontWeight: "bold", }} > Sign Up </Link></div>
                    <div onClick={gotoHome} className='position-absolute top-0 mp-close-icon p-2' style={{ cursor: 'pointer', }}>
                        {/* <FontAwesomeIcon color='white' fontSize={25} icon={faClose}></FontAwesomeIcon> */}
                        <img src={CloseIcon} className='' />
                    </div>
                </div>
            </div>
        </div>
    )
}

const mapStateToProps = (state: any) => ({
    userdata: state.UserStore
});

const mapDispatchToProps = (dispatch: any) => ({
    hideLoginPopup: () => dispatch({ data: PopupType.IDLE, type: 'popup' }),
    updateUserLogin: (data: any) => dispatch({ user: data, type: 'login' }),
});

export default connect(mapStateToProps, mapDispatchToProps)(Login);