import { Socket, io } from "socket.io-client";
import appConfig from "../../config/constant";
import { ReduxStore } from "../redux/store";
import { PlatformReducer } from "../redux/reducers/platform.reducer";
import { MsgDeliveryStatus, PlatformActionType } from "../redux/reducers/types";
import { useDispatch } from "react-redux";
import { JoinRoom } from "../model/dto/socket/join-room";
import { MultiplayerGameData } from "../model/dto/socket/multiplayer-game-data";
import { ExitGameRoom } from "../model/dto/socket/exit-game-room";

export class SocketSvc {
    private static socketIO: Socket;

    static initialize() {
        console.log('initialized socket');

        SocketSvc.socketIO = io(appConfig.SOCKET_URL, { secure: false });

        SocketSvc.socketIO.on('connect', SocketSvc.onConnect);
        SocketSvc.socketIO.on('disconnect', SocketSvc.onDisconnect);
        SocketSvc.socketIO.on('new_challenge', (data) => {
            ReduxStore.dispatch(({
                type: PlatformActionType.NEW_NOTIFICATION,
                data: data
            }));
        });
        SocketSvc.socketIO.on('new_message', SocketSvc.onMsgReceived);

        SocketSvc.socketIO.on('user_joined', this.onOpponentJoined);

        // use this to send the data from first joined player to next joined player
        SocketSvc.socketIO.on('request_gameplay', this.onRequestGameplay);

        SocketSvc.socketIO.on('game_data', this.onReceivedGameData);

        SocketSvc.socketIO.on('exit_game_room', this.onOpponentExit);

        SocketSvc.socketIO.on('end_game', this.onEndGame);

        SocketSvc.socketIO.on('typing_note', this.onReceiveTypingNote);

        SocketSvc.socketIO.on('new_message_delivery', this.onMsgDeliveryUpdate);
        
        SocketSvc.socketIO.on('user-connection', this.onFriendsLoginUpdates);
    }

    static connect(id: string) {
        SocketSvc.socketIO.emit('joinRoom', id);
    }

    static onConnect() {
        console.log('socket ---------------------------------------------', SocketSvc.socketIO.id);

    }

    static onDisconnect() {
        console.log('socket desconnected ');

    }

    static sendMsg(to: string, content: string, sender_id: string, id: string, user: string) {
        SocketSvc.socketIO.emit('new_message', {
            chat_id: to,
            sender_id,
            content,
            msg_id: id,
            user
        });
    }

    static onMsgReceived(msg: any) {
        console.log('new msg ', msg);
        msg.status = 0;
        ReduxStore.dispatch(({
            type: PlatformActionType.MSG_NOTIFICATION,
            data: msg
        }));
    }

    static joinMultiplayer(data: JoinRoom) {
        SocketSvc.socketIO.emit('join_multiplayer', data.room_id, data);
    }

    static onOpponentJoined(msg: JoinRoom) {
        console.log('onOpponentJoined ',msg);
        
        ReduxStore.dispatch(({
            type: PlatformActionType.MULTIPLAYER_USER_JOINED,
            data: msg
        }));
    }

    static onRequestGameplay(msg: any) {
        ReduxStore.dispatch(({
            type: PlatformActionType.MULTIPLAYER_REQUEST_GAMEPLAY,
            data: msg
        }));
    }

    static onReceivedGameData(data: MultiplayerGameData) {
        data.status = 1;
        ReduxStore.dispatch(({
            type: PlatformActionType.MULTIPLAYER_GAME_DATA,
            data
        }));
    }

    static sendGameplayRequest(data: JoinRoom): void {
        SocketSvc.socketIO.emit('gameplay_request', data.room_id, data);
    }

    static sendGameScore(data: MultiplayerGameData, room: string): void {
        SocketSvc.socketIO.emit('game_data', room, data);
    }

    static exitGameRoom(data: ExitGameRoom, room: string): void {
        SocketSvc.socketIO.emit('exit_game_room', room, data);
    }

    static endGame(data: MultiplayerGameData, room: string): void {
        SocketSvc.socketIO.emit('end_game', room, data)
    }
    static onOpponentExit(data: MultiplayerGameData): void {
        data.status = 0;
        ReduxStore.dispatch(({
            type: PlatformActionType.MULTIPLAYER_GAME_END,
            data
        }));
    }

    static onEndGame(data: MultiplayerGameData): void {
        console.log('end game called ', data);

        data.status = 1;
        ReduxStore.dispatch({ type: PlatformActionType.MULTIPLAYER_GAME_END, data });
    }

    static sendTypingNote(from: string, to: string): void {
        SocketSvc.socketIO.emit('typing_note', { from, to });
    }

    static onReceiveTypingNote(data: string): void {
        console.log('onReceiveTypingNote ', data);

        ReduxStore.dispatch({ type: PlatformActionType.MSG_TYPING_NOTIFICATION, data });
    }

    static onMsgDeliveryUpdate(data: MsgDeliveryStatus): void {
        ReduxStore.dispatch({ type: PlatformActionType.MSG_DELIVERY_STATUS, data });
    }

    static sendMsgStatus(room: string, data: MsgDeliveryStatus[]): void {
        SocketSvc.socketIO.emit('message_status', room, data);
    }

    static onFriendsLoginUpdates(data: MsgDeliveryStatus): void {
        ReduxStore.dispatch({ type: PlatformActionType.FRIENDS_LOGIN_CHECKS, data });
    }

    static get SocketID() {
        return this.socketIO.id;
    }
}
